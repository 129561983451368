import { api, AuthErrorCode } from "@/services/api"
import { apiTracesStats, type ApiTracesStats } from "./schemas"

/**
 * service
 */
export const service = {
  stats: async () => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/stats`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byBrowser: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/browser`, {
      params,
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byDevice: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/device`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byOs: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/os`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byDay: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/day`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byWeek: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/week`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byMonth: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/month`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  byYear: async (params: ServiceParams = {}) => {
    type RSuccess = { stats: ApiTracesStats }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`trackings/traces/year`, { params })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
  },
  trackings: {
    byBrowser: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/browser`, {
        params,
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byDevice: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/device`, {
        params,
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byOs: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/os`, { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byDay: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/day`, { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byWeek: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/week`, { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byMonth: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/month`, { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
    byYear: async (id: string, params: ServiceParams = {}) => {
      type RSuccess = { stats: ApiTracesStats }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`trackings/${id}/year`, { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { stats: apiTracesStats.parse(data.stats) }, error: false } as const
    },
  },
}

/**
 * types
 */
type ServiceParams = { date?: string; from?: string; to?: string; unique?: "true" }
