import { ContextMenu } from "@/components/ui/context-menu"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { useMenu } from "."
import { MenuContext, MenuContextType } from "./useMenu"

/**
 * MenuRoot
 */
type MenuRootProps = {
  type: MenuContextType["type"]
  menu: React.ReactNode
  disabled?: boolean
  asChild?: boolean
  children: React.ReactNode
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
} & React.ComponentPropsWithoutRef<"div">
const MenuRoot: React.FC<MenuRootProps> = ({
  type,
  menu,
  align,
  asChild = false,
  side,
  disabled = false,
  children,
  ...props
}) => {
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0
  if (disabled) return children
  if (type === "context-menu" && isTouchDevice) return children
  return (
    <MenuContext.Provider value={{ type }}>
      {type === "context-menu" ? (
        <ContextMenu>
          <ContextMenu.Trigger asChild={asChild}>{children}</ContextMenu.Trigger>
          <ContextMenu.Content {...props}>{menu}</ContextMenu.Content>
        </ContextMenu>
      ) : (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
          <DropdownMenu.Content align={align} side={side} {...props}>
            {menu}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </MenuContext.Provider>
  )
}

/**
 * MenuItem
 */
type MenuItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
const MenuItem: React.FC<MenuItemProps> = ({ ...props }) => {
  const { type } = useMenu()
  return type === "context-menu" ? (
    <ContextMenu.Item {...props} />
  ) : (
    <DropdownMenu.Item {...props} />
  )
}

/**
 * MenuItem
 */
type MenuSeparatorProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
const MenuSeparator: React.FC<MenuSeparatorProps> = ({ ...props }) => {
  const { type } = useMenu()
  return type === "context-menu" ? (
    <ContextMenu.Separator {...props} />
  ) : (
    <DropdownMenu.Separator {...props} />
  )
}

/**
 * Menu
 */
export const Menu = Object.assign(MenuRoot, {
  Context: MenuContext,
  Item: MenuItem,
  Separator: MenuSeparator,
})
