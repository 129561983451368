import { enGB, fr } from "date-fns/locale"
import { type Locale as DateFnsLocale } from "date-fns"

/**
 * i18nConfig
 */
const locales = ["en", "fr"] as const
export const i18nConfig: I18nConfig = {
  locales,
  defaultLocale: "en",
  fallbackLocale: "en",
  debug: false && process.env.VITE_ENV === "development",
  defaultNamespace: "translation",
  localeCodes: {
    en: "en-US",
    fr: "fr-FR",
  },
  dateFnsLocales: { en: enGB, fr, enGB },
}

/**
 * types
 */
export type Locale = (typeof locales)[number]
interface DateFnsLocales extends Record<Locale, DateFnsLocale> {
  enGB: DateFnsLocale
}
type I18nConfig = {
  locales: typeof locales
  defaultLocale: Locale
  fallbackLocale: Locale
  debug: boolean
  defaultNamespace: "translation"
  localeCodes: Record<Locale, string>
  dateFnsLocales: DateFnsLocales
}
