import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormHeader,
  FormImage,
  FormInput,
  FormSection,
  FormSubmit,
  formatDateToFormInput,
  useForm,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { resetAllStoresAndReload } from "@/store"
import { updateSession } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import { updateProfile } from "@/store/users/actions"
import { User } from "@/store/users/localizers"
import { match } from "ts-pattern"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/user-profile.json
 */
const dictionary = createContextMapper("components", "dialogs", "user-profile")

/**
 * UserProfileDialog
 *
 */
export const UserProfileDialog: React.FC<UseDialogProps<User>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-4xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<User>> = ({ item: user, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const isMe = useMe().id === user.id
  const form = useForm({
    values: {
      ...D.selectKeys(user.profile, baseFields),
      image: {
        url: user.profile.image,
        file: null,
        delete: false,
      },
      dob: formatDateToFormInput(user.profile.dob),
      ...user.profile.address,
    },
    onSubmit: async ({ values }) => {
      const payload = {
        ...D.selectKeys(values, baseFields),
        dob: values.dob === "" ? null : values.dob,
        address: D.selectKeys(values, addressFields),
        image: D.selectKeys(values.image, ["file", "delete"]),
      }
      return match(await updateProfile(user.id, payload))
        .with({ error: false }, () => {
          onOpenChange(false)
          toast.success(_("success"))
          if (isMe) updateSession()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(() => void toast.error(_errors("FETCH_ERROR")))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-personal-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-personal-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className="@xl/dialog:grid grid-cols-3">
        <FormSection className="col-span-2">
          <FormInput
            label={_form("firstname-label")}
            name="firstname"
            placeholder={_form("firstname-placeholder")}
            auto="given-name"
          />
          <FormInput
            label={_form("lastname-label")}
            name="lastname"
            placeholder={_form("lastname-placeholder")}
            auto="family-name"
          />
          <FormInput label={_form("dob-label")} name="dob" type="date" auto="bday-day" />
        </FormSection>
        <FormImage label={_("image-label")} name="image" ratio="aspect-square" />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-employment-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-employment-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className="@xl/dialog:grid grid-cols-2">
        <FormInput
          label={_form("company-label")}
          name="company"
          placeholder={_form("company-placeholder")}
          auto="organization"
        />
        <FormInput
          label={_form("position-label")}
          name="position"
          placeholder={_form("position-placeholder")}
          auto="organization-title"
        />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-contact-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-contact-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_form("phones-label")}
        name="phones"
        translate={t => _form(`phones-${t}`)}
        type="tel"
        auto={{ name: "new-phone", value: "tel" }}
      />
      <FormExtraFields
        label={_form("emails-label")}
        name="emails"
        translate={t => _form(`emails-${t}`)}
        type="email"
        auto={{ name: "new-email", value: "email" }}
      />

      <FormHeader>
        <FormHeader.Title>{_("section-address-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-address-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className="@xl/dialog:grid grid-cols-3">
        <FormInput
          className="col-span-2"
          label={_form("address-street-label")}
          name="address"
          auto="street-address"
          placeholder={_form("address-street-placeholder")}
        />
        <FormInput
          label={_form("address-city-label")}
          name="city"
          placeholder={_form("address-city-placeholder")}
        />
        <FormInput
          label={_form("address-state-label")}
          name="state"
          placeholder={_form("address-state-placeholder")}
        />
        <FormInput
          label={_form("address-zip-label")}
          name="zip"
          placeholder={_form("address-zip-placeholder")}
          auto="postal-code"
        />
        <FormInput
          label={_form("address-country-label")}
          name="country"
          placeholder={_form("address-country-placeholder")}
          auto="country"
        />
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-additional-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-additional-description")}</FormHeader.Description>
      </FormHeader>
      <FormExtraFields
        label={_form("extras-label")}
        name="extras"
        translate={t => _form(`extras-${t}`)}
        auto={{ value: false }}
      />

      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
const baseFields = [
  "firstname",
  "lastname",
  "position",
  "company",
  "phones",
  "emails",
  "extras",
] as const
const addressFields = ["address", "city", "country", "state", "zip"] as const
