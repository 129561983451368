import { apiContentItemBase, apiContentItemTranslation } from "@/services/contents/schemas"
import { z } from "zod"

/**
 * schemas
 */
export const itemType = "actionCards"
export const itemProps = z.object({
  titleLevel: z.number(),
  cards: z.string().array(),
})
export const itemTranslation = apiContentItemTranslation.extend({
  props: z.object({
    title: z.string(),
    secondary: z.string(),
    description: z.string(),
    cards: z.record(
      z.object({
        title: z.string(),
        description: z.string(),
        type: z.enum(["button", "links"]),
        links: z.record(
          z.object({
            order: z.number(),
            icon: z.string(),
            text: z.string(),
            url: z.string(),
            blank: z.boolean(),
          })
        ),
      })
    ),
  }),
})

/**
 * exports
 */
export const apiContentItem = apiContentItemBase.extend({
  type: z.literal(itemType),
  props: itemProps,
  translations: itemTranslation.array(),
})
export type ApiContentItem = z.infer<typeof apiContentItem>
export type ItemType = typeof itemType
export type ItemProps = z.infer<typeof itemProps>
export type ItemTranslation = z.infer<typeof itemTranslation>
export type ContentItem = { type: ItemType; props: ItemProps; translations: ByLanguage<ItemTranslation> }
