import { type Locale, format, isBefore } from "date-fns"
import { i18nConfig } from "@/config/i18n"

/**
 * sortCompare
 */
export const sortCompare =
  <T extends Record<string, string | Date | number | unknown>>(
    field: keyof T,
    direction: "ASC" | "DESC" | boolean = "ASC",
    fallbackStr = NONCHAR
  ) =>
  (a: T, b: T) => {
    const descending = direction === "DESC" || direction === true
    const { itemA, itemB } = descending
      ? { itemA: b[field], itemB: a[field] }
      : { itemA: a[field], itemB: b[field] }
    if (G.isString(itemA) && G.isString(itemB))
      return (itemA || fallbackStr).localeCompare(itemB || fallbackStr)
    if (G.isDate(itemA) && G.isDate(itemB)) return isBefore(itemA, itemB) ? -1 : 1
    if (G.isNumber(itemA) && G.isNumber(itemB)) return itemA - itemB
    return 0
  }

/**
 * normString
 */
export const normString = (term: string) => {
  return term
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[.,/"#!$%^&*;:{}=\-_`~()]/g, "")
}

/**
 * normSearch
 */
export const normSearch = (search: string) => {
  return normString(search.replace(/\s+/g, " "))
}

/**
 * searchCompare
 */
export const searchCompare = (
  search: string,
  searchIn: string | Date,
  locale: Locale = i18nConfig.dateFnsLocales.en
) => {
  const normIn = normSearch(
    G.isDate(searchIn) ? format(searchIn, "PPPP p HH'h'mm ddMMyy ddMMyyyy", { locale }) : searchIn
  )
  const term = normSearch(search)
  return normIn.includes(term)
}

// the very first unicode character
export const NULLCHAR = "\u{0000}"

// the very last unicode character
export const NONCHAR = "\u{10FFFF}"
