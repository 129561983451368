import { Skeleton } from "@/components/ui/skeleton"
import { SrOnly } from "@/components/ui/sr-only"
import { Video } from "@/components/ui/video"
import { HN } from "@/components/ui/hn"

import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { relativeProseStyle } from "../../frontend/proseStyle"
import { ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const { titleLevel, displayNext } = item.props
  const { title, secondary, description, linkUrl, linkText } = t(item).props
  const video = useMediasFile(t(item).props.video)

  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [play, setPlay] = React.useState(false)
  const togglePlay = () => {
    if (!videoRef.current) return
    if (play) videoRef.current.pause()
    else videoRef.current.play()
    setPlay(!play)
  }
  const [muted, setMuted] = React.useState(true)
  const toggleMute = () => {
    if (!videoRef.current) return
    setMuted(!muted)
  }

  const iconCx = "size-2 @2xl/card:size-4"
  return (
    <article className="text-white rounded-b-md font-museo">
      <div className="relative flex max-w-[1200px] aspect-video mx-auto p-4 @2xl/card:p-16 gap-[57px]">
        <div className="absolute inset-0 w-full h-full bg-be-midnight">
          {G.isNotNullable(video) && (
            <Video
              className="object-cover w-full h-full"
              wrapperCx="w-full h-full"
              src={video.url}
              ref={videoRef}
              controls={false}
              ratio=""
            />
          )}
          <div className="absolute inset-0 bg-[#00265780]" aria-hidden />
        </div>
        {G.isNotNullable(video) && (
          <div className="absolute bottom-4 right-4 @2xl/card:static flex @2xl/card:flex-col @2xl/card:justify-end @2xl/card:h-full gap-2.5">
            <button
              className="inline-flex justify-center items-center shrink-0 size-6 @2xl/card:size-10 rounded-full bg-white/20 backdrop-blur-md"
              type="button"
              onClick={togglePlay}
            >
              {play ? (
                <Pause aria-hidden className={iconCx} />
              ) : (
                <Play aria-hidden className={iconCx} />
              )}
              <SrOnly>{_(play ? "pause" : "play")}</SrOnly>
            </button>
            <button
              className="inline-flex justify-center items-center shrink-0 size-6 @2xl/card:size-10 rounded-full bg-white/20 backdrop-blur-md"
              type="button"
              onClick={toggleMute}
            >
              {muted ? (
                <Mute aria-hidden className={iconCx} />
              ) : (
                <Unmute aria-hidden className={iconCx} />
              )}
              <SrOnly>{_(muted ? "unmute" : "mute")}</SrOnly>
            </button>
          </div>
        )}
        <div className="relative flex flex-col justify-start grow">
          <div className="flex flex-col justify-center items-start grow max-w-sm">
            {S.isNotEmpty(S.trim(secondary)) && (
              <p className="pb-2.5 @2xl/card:pb-5 text-xs @2xl/card:text-base leading-normal font-semibold text-be-cornflower uppercase">
                {secondary}
              </p>
            )}
            <HN
              className="w-full max-w-sm uppercase text-[25px] @2xl/card:text-[40px] leading-tight font-bold text-white"
              level={titleLevel}
            >
              {title}
              <Skeleton value={title} />
              <Skeleton className="w-3/4" value={title} />
            </HN>
            {S.isNotEmpty(S.trim(description)) && (
              <div
                className={cx(
                  relativeProseStyle,
                  "w-full max-w-lg text-sm @2xl/card:text-base text-white"
                )}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {S.isNotEmpty(S.trim(linkUrl)) && (
              <a
                href={linkUrl}
                className="inline-flex items-center mt-[10px] px-5 py-2.5 rounded-[25px] bg-be-orient text-be-button text-white uppercase"
              >
                {linkText}
              </a>
            )}
          </div>
          {displayNext && (
            <span
              className="hidden @5xl/card:inline-flex justify-center items-center w-10 h-[64px] rounded-full bg-white/20 backdrop-blur-md text-white"
              aria-hidden
            >
              <ArrowBottom />
            </span>
          )}
        </div>
      </div>
    </article>
  )
}

/**
 * icons
 */
const ArrowBottom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="CurrentColor"
      strokeLinecap="square"
      strokeWidth={1.5}
      d="M10 3.125v13.75m0 0L4.375 11.25M10 16.875l5.625-5.625"
    />
  </svg>
)
const Play = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M14.566 8.522 4.042 2.334a.602.602 0 0 0-.811.194.546.546 0 0 0-.081.284v12.375c0 .1.028.199.08.285a.575.575 0 0 0 .22.206.604.604 0 0 0 .592-.013l10.524-6.187a.566.566 0 0 0 .208-.203.538.538 0 0 0 0-.55.566.566 0 0 0-.208-.203Z"
    />
  </svg>
)
const Pause = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.25 2.375H3.75c-.76 0-1.375.616-1.375 1.375v12.5c0 .76.616 1.375 1.375 1.375h12.5c.76 0 1.375-.616 1.375-1.375V3.75c0-.76-.616-1.375-1.375-1.375Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Unmute = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g stroke="currentColor" strokeWidth={1.5} clipPath="url(#a)">
      <path
        strokeLinecap="round"
        d="M6.25 13.125H2.5a.625.625 0 0 1-.625-.625v-5a.625.625 0 0 1 .625-.625h3.75L11.875 2.5v15L6.25 13.125Z"
      />
      <path strokeLinecap="square" strokeLinejoin="round" d="M15 8.125v3.75M17.5 6.875v6.25" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Mute = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeWidth={1.5} clipPath="url(#a)">
      <path
        strokeLinecap="round"
        d="M5.625 11.813H2.25a.563.563 0 0 1-.563-.563v-4.5a.563.563 0 0 1 .563-.563h3.375l5.063-3.937v13.5l-5.063-3.938Z"
      />
      <path
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M16.875 7.313 13.5 10.688M16.875 10.688 13.5 7.312"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
