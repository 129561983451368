import { parseISO } from "date-fns"
import { makePathToApi } from "@/fns/File"
import { ApiUser, ApiUserProfile } from "@/services/users/schemas"

/**
 * localizeUser
 */
export const localizeUser = (parsed: ApiUser) => ({
  ...D.selectKeys(parsed, ["id", "email", "role", "status"]),
  profile: localizeUserProfile(parsed.profile),
  deletedAt: G.isNotNullable(parsed.deletedAt) ? parseISO(parsed.deletedAt) : null,
  lastLoginAt: G.isNotNullable(parsed.lastLoginAt) ? parseISO(parsed.lastLoginAt) : null,
  createdAt: parseISO(parsed.createdAt),
  updatedAt: parseISO(parsed.updatedAt),
})
export type User = ReturnType<typeof localizeUser>

/**
 * localizeUserProfile
 */
export const localizeUserProfile = (parsed: ApiUserProfile) => ({
  ...D.selectKeys(parsed, [
    "firstname",
    "lastname",
    "position",
    "company",
    "phones",
    "emails",
    "address",
    "extras",
  ]),
  dob: G.isNotNullable(parsed.dob) ? parseISO(parsed.dob) : null,
  image: S.isNotEmpty(parsed.image.url) ? makePath(parsed.image.url) : null,
  preview: S.isNotEmpty(parsed.image.previewUrl) ? makePath(parsed.image.previewUrl) : null,
  thumbnail: S.isNotEmpty(parsed.image.thumbnailUrl) ? makePath(parsed.image.thumbnailUrl) : null,
})
export type UserProfile = ReturnType<typeof localizeUserProfile>

/**
 * helpers
 */
const makePath = (path: string) => (S.isNotEmpty(path) ? makePathToApi(path) : null)
