import { Link as WouterLink, type LinkProps as WouterLinkProps } from "wouter"

/**
 * Link
 */
type LinkProps = WouterLinkProps
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <WouterLink {...props}>
        <a className={className} ref={ref}>
          {children}
        </a>
      </WouterLink>
    )
  }
)
const linkCx = "text-primary hover:underline transition-colors"

/**
 * exports
 */
export { Link, linkCx, type LinkProps }
