import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"

import { useRefreshArticleCategories } from "@/store/articles/hooks"
import Collection from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Page: ArticlesCategories
 */
const ArticlesCategories: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  useRefreshArticleCategories()
  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader
        breadcrumbs={[
          [_("breadcrumbs"), "/dashboard/articles"],
          [_("categories.breadcrumbs"), "/dashboard/articles/categories"],
        ]}
      />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <ArticlesCategories />
  </ContextProvider>
)
