import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { resetAllStoresAndReload } from "@/store"
import { createMediasFolder } from "@/store/medias/actions"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * CreateFolderDialog
 */
export const CreateFolderDialog: React.FC<UseDialogProps<string | null>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("create-folder-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<string | null>> = ({
  item: parentId,
  onOpenChange,
}) => {
  const { _ } = useDictionary(dictionary("create-folder-dialog"))

  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: { name: "" },
    validate: validator({
      name: [min(1, _("name-required"))],
    }),
    onSubmit: async ({ values: { name } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      const payload = { name, parentId }
      return match(await createMediasFolder(payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("name-label")} name="name" placeholder={_("name-placeholder")} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
