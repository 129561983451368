import { FormSelectOption } from "@/components/form/select"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "form")

export const useSideOptions = (): FormSelectOption[] => {
  const { _ } = useDictionary(dictionary())
  const titleLevelOption = React.useMemo(
    () => [
      {
        label: _("side-left"),
        value: "left",
      },
      {
        label: _("side-right"),
        value: "right",
      },
    ],
    [_]
  )
  return titleLevelOption
}
