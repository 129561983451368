import { Illustration } from "@/components/ui/glowing-stars"
import { Spotlight } from "@/components/ui/spotlight"
import { useSpotlight } from "@/components/ui/hooks/useSpotlight"

/**
 * CardRoot
 */
const CardRoot = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx("rounded-lg border bg-card text-card-foreground shadow-md", className)}
      {...props}
    />
  )
)

/**
 * CardSpotlight
 */
const CardSpotlight: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  const { cardProps, cardSpotlightProps } = useSpotlight()
  return (
    <div
      className={cx(
        "relative rounded-lg border bg-card text-card-foreground shadow-sm overflow-hidden",
        className
      )}
      {...cardProps}
      {...props}
    >
      {children}
      <Spotlight {...cardSpotlightProps} />
    </div>
  )
}

/**
 * CardGlowingStars
 */
const CardGlowingStars: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    row?: number
    columns?: number
    illustrationCx?: ClassName
  }
> = ({ className, row = 6, columns = 18, illustrationCx, children, ...props }) => {
  const [mouseEnter, setMouseEnter] = React.useState(false)
  return (
    <div
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      className={cx("relative bg-card rounded-lg border text-card-foreground", className)}
      {...props}
    >
      <Illustration
        mouseEnter={mouseEnter}
        stars={row * columns}
        columns={columns}
        className={illustrationCx}
      />
      {children}
    </div>
  )
}

/**
 * CardHeader
 */
const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("relative flex flex-col space-y-1.5 p-6", className)} {...props} />
  )
)

/**
 * CardTitle
 */
const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3
      ref={ref}
      className={cx("text-2xl font-semibold leading-none tracking-tight", className)}
      {...props}
    />
  )
)

/**
 * CardDescription
 */
const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cx("text-sm text-muted-foreground", className)} {...props} />
))

/**
 * CardContent
 */
const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("p-6 pt-0", className)} {...props} />
  )
)

/**
 * CardFooter
 */
const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("flex items-center p-6 pt-0", className)} {...props} />
  )
)

/**
 * exports
 */
const Card = Object.assign(CardRoot, {
  Spotlight: CardSpotlight,
  GlowingStars: CardGlowingStars,
  Header: CardHeader,
  Title: CardTitle,
  Description: CardDescription,
  Content: CardContent,
  Footer: CardFooter,
})
export { Card }
