import { parseISO } from "date-fns"
import { byLanguage } from "../languages/helpers"
import { localizeContent } from "../contents/localizers"
import { localizeSeo } from "../seos/localizers"
import { ApiArticle, ApiArticleCategory } from "@/services/articles/schemas"

/**
 * localizeArticle
 */
export const localizeArticle = ({
  seo,
  content,
  categoryId,
  authorId,
  publishedAt,
  publishedFrom,
  publishedTo,
  createdAt,
  updatedAt,
  ...parsed
}: ApiArticle) => ({
  ...D.selectKeys(parsed, ["id", "type", "state", "trackingId"]),
  seo: localizeSeo(seo),
  content: localizeContent(content),
  category: categoryId,
  author: authorId,
  publishedAt: parseISO(publishedAt),
  publishedFrom: publishedFrom ? parseISO(publishedFrom) : null,
  publishedTo: publishedTo ? parseISO(publishedTo) : null,
  createdAt: parseISO(createdAt),
  updatedAt: parseISO(updatedAt),
})
export type Article = ReturnType<typeof localizeArticle>

/**
 * localizeArticleCategory
 */
export const localizeArticleCategory = (parsed: ApiArticleCategory) => ({
  ...D.selectKeys(parsed, ["id", "order"]),
  translations: byLanguage(parsed.translations),
})
export type ArticleCategory = ReturnType<typeof localizeArticleCategory>
