import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"
import { ArrowDownNarrowWide, ArrowDownWideNarrow } from "lucide-react"

/**
 * ToolbarSort
 */
type Props = {
  size?: "xs" | "sm" | "default"
  dictionary: string
  sort: {
    field: string
    direction: "ASC" | "DESC"
  }
  toggle: (field: string) => void
  isDESC: (field: string) => boolean
  isActive: (field: string) => boolean
  list: string[]
}
export const ToolbarSort: React.FC<Props> = ({
  size = "default",
  dictionary,
  sort,
  toggle,
  isDESC,
  isActive,
  list,
}) => {
  const { _ } = useDictionary("components.layout.toolbar")
  const { _: translate } = useDictionary<string, false>(dictionary)
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant="secondary" size={size} icon aria-label={_("sort-button")}>
          {sort.direction === "ASC" ? (
            <ArrowDownNarrowWide aria-hidden />
          ) : (
            <ArrowDownWideNarrow aria-hidden />
          )}
          <SrOnly>{_("sort-button")}</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" side="bottom" className="w-56">
        {A.map(list, item => (
          <DropdownMenu.Item onClick={() => toggle(item)} active={isActive(item)} key={item}>
            {isDESC(item) ? (
              <ArrowDownNarrowWide aria-hidden />
            ) : (
              <ArrowDownWideNarrow aria-hidden />
            )}
            {translate(item)}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
