import { apiContentItemBase, apiContentItemTranslation } from "@/services/contents/schemas"
import { z } from "zod"

/**
 * schemas
 */
export const itemType = "accessibilityTabs"
export const itemProps = z.object({
  tabs: z.string().array(),
})
export const itemTranslation = apiContentItemTranslation.extend({
  props: z.object({
    tabs: z.record(
      z.object({
        title: z.string(),
        image: z.string().nullable(),
        content: z.string(),
        icon: z.string().nullable(),
        imageSide: z.string().default("right"),
        cornerDecoration: z.string().array(),
        cornerClip: z.string().array(),
      })
    ),
  }),
})

/**
 * exports
 */
export const apiContentItem = apiContentItemBase.extend({
  type: z.literal(itemType),
  props: itemProps,
  translations: itemTranslation.array(),
})
export type ApiContentItem = z.infer<typeof apiContentItem>
export type ItemType = typeof itemType
export type ItemProps = z.infer<typeof itemProps>
export type ItemTranslation = z.infer<typeof itemTranslation>
export type ContentItem = { type: ItemType; props: ItemProps; translations: ByLanguage<ItemTranslation> }
