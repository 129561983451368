import { textPlaceholder } from "@/fns/String"
import { SrOnly } from "@/components/ui/sr-only"

import { useTranslation } from "@/store/languages/hooks"
import { ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"
import { HN } from "@/components/ui/hn"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { _ } = useDictionary(dictionary("render"))
  const { title, secondary } = t(item).props
  const [titleStart, titleEnd] = splitTitle(textPlaceholder(title, _("title")))
  return (
    <article className="px-4 @lg/card:px-8 py-[50px] @lg/card:py-[80px] bg-be-midnight rounded-b-md font-museo text-white">
      <div className="flex flex-col @3xl/card:grid grid-cols-2 w-full max-w-[1000px] mx-auto gap-8">
        <div className="flex flex-col justify-center gap-[20px]">
          <HN className="max-w-lg text-be-title font-semibold uppercase" level={2}>
            {titleStart} <span className="text-be-malibu">{titleEnd}</span>
          </HN>
          <p className="text-base leading-6">{textPlaceholder(secondary, _("secondary"))}</p>
        </div>
        <div className="flex items-center">
          <form className="flex items-center w-full p-[5px] gap-[5px] rounded-[26px] bg-white">
            <SrOnly as="label" htmlFor="newsletter-email">
              {_("email-label")}
            </SrOnly>
            <div className="flex-1">
              <input
                className="w-full px-[30px] h-[42px] rounded-full bg-transparent"
                id="newsletter-email"
                placeholder={_("email-placeholder")}
              />
            </div>
            <button
              className="flex items-center h-[42px] px-[20px] gap-3 rounded-[26px] bg-be-orient text-be-button text-white uppercase"
              type="submit"
            >
              {_("submit")}
            </button>
          </form>
        </div>
      </div>
    </article>
  )
}
const splitTitle = (text: string): [string, string] => {
  const words = text.trim().split(" ")
  if (words.length <= 1) return ["", ""]
  const lastWord = words.length > 0 ? A.getUnsafe(words, words.length - 1) : ""
  words.pop()
  return [words.join(" "), lastWord]
}
