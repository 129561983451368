import globalConfig, { acceptedFileExtensions } from "@/config/global"
import { makeBreakable } from "@/fns/String"
import { humanFileSize } from "@/fns/human"
import {
  acceptToInputAccept,
  checkExtFromFile,
  formatExtList,
  getExtFromFile,
  getSizeFromFile,
  useDropZone,
} from "@/hooks/useDropZone"
import { saveAs } from "file-saver"
import { Download, X } from "lucide-react"
import selectFiles from "select-files-capture"
import { Form, FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "."
import { AdaptHeight } from "../ui/adapt-height"
import { Button } from "../ui/button"
import { Popover } from "../ui/popover"
import { SrOnly } from "../ui/sr-only"

/**
 * FormFiles
 */

type Props = FormInputFilesProps & FormFieldWrapperProps
export const FormFiles = React.forwardRef<HTMLButtonElement, Props>(
  ({ label, labelAside, name, info, ...props }, ref) => (
    <FormFieldWrapper {...{ label, labelAside, name, info }}>
      <FormInputFiles {...props} ref={ref} />
    </FormFieldWrapper>
  )
)

/**
 * FormInputFiles
 */
type FormInputFilesProps = React.ComponentProps<typeof Form.Input> & {
  accept?: string[]
  min?: number
  max?: number
  multiple?: boolean
}
export const FormInputFiles = React.forwardRef<HTMLButtonElement, FormInputFilesProps>(
  (
    {
      accept = acceptedFileExtensions,
      min = 0,
      max = globalConfig.maxUploadFile,
      multiple = true,
      className,
    },
    ref
  ) => {
    const { _ } = useDictionary("components.form.form-files")
    const { setFieldValue, value, id } = useFieldContext<FormFileType[]>()

    // manage file picker and drop zone
    const onDropFiles = (files: File[]) => {
      if (A.isNotEmpty(files)) multiple ? setFieldValue([...value, ...files]) : setFieldValue(files)
    }
    const onError = (code: "TOOLARGE" | "UNACCEPTED") => {
      toast.error(_(code))
    }
    const onClickDropZone = async () => {
      const fileList = await selectFiles({ accept: acceptToInputAccept(accept), multiple })
      const files = fileList ? Array.from(fileList) : []
      if (!A.some(files, file => min <= getSizeFromFile(file) && max >= getSizeFromFile(file)))
        return onError("TOOLARGE")
      if (!A.some(files, file => checkExtFromFile(file, accept))) return onError("UNACCEPTED")
      onDropFiles(files)
    }
    const { bindDropZone, dragOver } = useDropZone({
      accept,
      min,
      max,
      multiple,
      onDropFiles,
      onError,
    })

    // manage files
    const removeFile = (index: number) => {
      const current = A.getUnsafe(value, index)
      if (isSynteticFile(current)) {
        return setFieldValue(A.replaceAt(value, index, { ...current, delete: true }))
      }
      setFieldValue(A.removeAt(value, index))
    }

    return (
      <div className="flex flex-col gap-2">
        <div
          className={cx(
            "relative flex justify-center items-center w-full rounded-md",
            "border border-input border-dashed focus-within:border-orient transition-colors",
            dragOver ? "bg-primary/5 border-primary" : "bg-card",
            className
          )}
          {...bindDropZone}
        >
          <DropInner ref={ref} {...{ id, accept, min, max, onClickDropZone }} />
        </div>
        <FilesList files={value} removeFile={removeFile} />
      </div>
    )
  }
)

/**
 * DropInner
 */
type DropInnerProps = {
  id: string
  accept: string[]
  min?: number
  max: number
  multiple?: boolean
  onClickDropZone: React.MouseEventHandler<HTMLButtonElement>
}
const DropInner = React.forwardRef<HTMLButtonElement, DropInnerProps>(
  ({ id, onClickDropZone, max, accept }, ref) => {
    const { _ } = useDictionary("components.form.form-files")
    const acceptedExtensions = React.useMemo(() => formatExtList(accept), [accept])
    return (
      <div className="flex flex-col justify-center items-center min-h-[10rem] p-4 gap-1">
        <p className="text-base">
          {_("placeholder-before")}
          <Button
            variant="link"
            size="link"
            id={id}
            onClick={onClickDropZone}
            ref={ref}
            className="text-base underline"
          >
            {_("placeholder-button")}
          </Button>
          {_("placeholder-after")}
        </p>
        <p className="text-xs text-muted-foreground">
          ({<span>{_("max-file-size", { size: humanFileSize(max) })}</span>},
          <Popover>
            <Popover.Trigger asChild>
              <Button variant="link" size="link" className="text-xs underline">
                {_("accept")}
              </Button>
            </Popover.Trigger>
            <Popover.Content>
              <p className="text-xs">
                <span className="inline-block pb-1 font-bold">{_("accept-extensions")}</span>
                <br />
                {A.join(acceptedExtensions, ", ")}
              </p>
            </Popover.Content>
          </Popover>
          )
        </p>
      </div>
    )
  }
)

/**
 * FilesList
 */
type FilesListProps = {
  files: FormFileType[]
  removeFile: (index: number) => void
}
const FilesList: React.FC<FilesListProps> = ({ files, removeFile }) => {
  return A.isNotEmpty(files) ? (
    <AdaptHeight>
      <div className="flex flex-col gap-2">
        {A.mapWithIndex(files, (index, formFile) => (
          <FilesItem formFile={formFile} remove={() => removeFile(index)} key={index} />
        ))}
      </div>
    </AdaptHeight>
  ) : null
}

/**
 * FilesList
 * dictionary src/dictionaries/en/components/form/form-files.json
 */
type FilesItemProps = {
  formFile: FormFileType
  remove: React.MouseEventHandler<HTMLButtonElement>
}
const FilesItem: React.FC<FilesItemProps> = ({ formFile, remove }) => {
  const { _ } = useDictionary("components.form.form-files")
  const file = normalizeFormFile(formFile)
  return file.delete === false ? (
    <div className="flex justify-between items-center gap-1 w-full px-4 py-2 border border-input rounded-md">
      <div className="flex flex-col gap-y-1">
        <p className="text-sm font-medium">
          {/* {file.name} */}
          {makeBreakable(file.name)}
        </p>
        <p className="flex items-center text-xs text-muted-foreground">
          {humanFileSize(file.size)}
          {isSynteticFile(formFile) ||
            (true && (
              <Button variant="link" size="link" onClick={() => saveAs(file.url, file.name)}>
                <Download aria-hidden size={12} />
                <SrOnly>{_("download-file", { file: file.name })}</SrOnly>
              </Button>
            ))}
        </p>
      </div>
      <Button variant="ghost" size="xs" icon onClick={remove}>
        <X aria-hidden />
        <SrOnly>{_("remove-file", { file: file.name })}</SrOnly>
      </Button>
    </div>
  ) : null
}

/**
 * helpers
 */
export const isSynteticFile = (file: FormFileType): file is SynteticFile => {
  return G.isNotNullable((file as SynteticFile).delete)
}
export const isFile = (file: FormFileType): file is File => {
  return !isSynteticFile(file)
}
const normalizeFormFile = (file: FormFileType): NormalizedFile => {
  if (isSynteticFile(file)) return { ...file }
  return {
    name: file.name,
    extension: getExtFromFile(file) ?? "",
    size: file.size,
    url: URL.createObjectURL(file),
    delete: false,
  }
}

/**
 * types
 */
export type FormFileType = File | SynteticFile
type SynteticFile = {
  id: string
  name: string
  extension: string
  size: number
  url: string
  delete: boolean
}
type NormalizedFile = {
  name: string
  extension: string
  size: number
  url: string
  delete: boolean
}
