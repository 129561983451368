import {
  Form,
  FormAssertive,
  FormFiles,
  FormInput,
  FormSubmit,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { updateMediasFile } from "@/store/medias/actions"
import { MediasFile } from "@/store/medias/localizers"
import { ValuesValidate } from "use-a11y-form"
import { match } from "ts-pattern"
import { resetAllStoresAndReload } from "@/store"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "edit-file-dialog")

/**
 * EditFolderDialog
 */
export const EditFileDialog: React.FC<UseDialogProps<MediasFile>> = props => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-xl">
      {props.item !== false && <DialogForm {...props} item={props.item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<MediasFile>> = ({ item, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const languagesById = useLanguagesById()
  const initialValue = useMemoOnce(() => ({
    files: [] as File[],
    translations: D.map(languagesById, language => ({
      languageId: language.id,
      name: translate(item, language)?.name ?? "",
      alt: translate(item, language)?.alt ?? "",
      caption: translate(item, language)?.caption ?? "",
    })),
  }))
  const form = useForm({
    allowSubmitAttempt: true,
    values: initialValue,
    translate: _ as (ctx: string) => string,
    validate: values => ({
      translations: D.map(values.translations, translation => ({
        name: !translation.name && _("name-required"),
      })) as ValuesValidate<typeof initialValue>["translations"],
    }),
    onSubmit: async ({ values: { files, translations } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return "VALIDATION_FAILURE"
      const payload = {
        file: A.isNotEmpty(files) ? A.getUnsafe(files, 0) : undefined,
        translations: D.values(translations),
      }
      return match(await updateMediasFile(item.id, payload))
        .with({ error: false }, () => {
          onOpenChange(false)
          toast.success(_("success"))
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormInput label={_("name-label")} name="name" placeholder={_("name-placeholder")} />
            <FormInput label={_("alt-label")} name="alt" placeholder={_("alt-placeholder")} />
            <FormInput
              label={_("caption-label")}
              name="caption"
              placeholder={_("caption-placeholder")}
            />
          </div>
        )}
      </FormTranslationTabs>
      <FormFiles label={_("files-label")} name="files" multiple={false} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
