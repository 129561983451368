import { ucFirst } from "@/fns/String"
import {
  Locale,
  setMonth,
  setWeek,
  setYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns"

export const toRechartsData = (stats: Record<string, number>) =>
  D.toPairs(stats).map(([key, value]) => ({ name: ucFirst(key), value }))

export const parseIsoWeek = (yearWeek: string, locale: Locale): Date => {
  const [year, week] = A.map(S.split(yearWeek, "-"), Number)
  if (G.isNullable(year) || G.isNullable(week)) throw new Error("Invalid yearWeek")
  return startOfWeek(setWeek(setYear(startOfYear(new Date()), year), week), { locale })
}

export const parseIsoMonth = (yearMonth: string): Date => {
  const [year, month] = A.map(S.split(yearMonth, "-"), Number)
  if (G.isNullable(year) || G.isNullable(month)) throw new Error("Invalid yearMonth")
  return startOfMonth(setMonth(setYear(startOfYear(new Date()), year), month))
}

export const parseIsoYear = (year: string): Date => {
  return startOfYear(new Date(+year, 1, 1))
}
