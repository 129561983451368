import deepMerge from "ts-deepmerge"

/**
 * contextMapper
 */
export const contextMapper = <T extends string[]>(...contextes: T) => {
  return A.join(contextes, ".") as ConcatenateAll<T>
}
type ConcatenateAll<T extends string[]> = T extends [infer First, ...infer Rest]
  ? First extends string
    ? Rest extends string[]
      ? `${First}${Rest["length"] extends 0 ? "" : "."}${ConcatenateAll<Rest>}`
      : First
    : never
  : ""
export const createContextMapper = <T extends string[]>(...contextes: T) => {
  return <U extends string[]>(...subContextes: U) => {
    return contextMapper(...contextes, ...subContextes)
  }
}

/**
 * deepMergeDictionaries
 */
export const deepMergeDictionaries = <T extends Dictionary[]>(...dictionaries: T): MergeDictionaries<T> => {
  // @ts-expect-error
  return deepMerge(...dictionaries)
}

type MergeDictionaries<T extends Dictionary[]> = T extends [infer F, ...infer R]
  ? F extends Dictionary
    ? R extends Dictionary[]
      ? {
          [K in keyof F | keyof MergeDictionaries<R>]: K extends keyof F
            ? K extends keyof MergeDictionaries<R>
              ? Merge<F[K], MergeDictionaries<R>[K]>
              : F[K]
            : K extends keyof MergeDictionaries<R>
            ? MergeDictionaries<R>[K]
            : never
        }
      : never
    : never
  : object

type Merge<A, B> = A extends Dictionary
  ? B extends Dictionary
    ? MergeDictionaries<[A, B]>
    : A
  : B extends Dictionary
  ? B
  : A | B
