import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

import { Skeleton } from "@/components/ui/skeleton"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemThumbnail
 */
export const ItemThumbnail: ItemMappingExport<ItemType>["ItemThumbnail"] = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className="absolute inset-0 w-full h-full text-foreground/25"
      >
        <rect
          width={82.96}
          height={5.54}
          x={18.25}
          y={57.46}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={106}
          height={11.09}
          x={18.25}
          y={38.98}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={137.68}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={56.97}
          height={6.33}
          x={107.48}
          y={126.2}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={79.13}
          height={41.15}
          x={102.62}
          y={119.87}
          rx={1.3}
          ry={1.3}
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeMiterlimit: 10,
            strokeWidth: ".25px",
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={142.89}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={148.11}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={44.21}
          height={3.96}
          x={107.48}
          y={153.32}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={137.68}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={56.97}
          height={6.33}
          x={23.6}
          y={126.2}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={79.13}
          height={41.15}
          x={18.75}
          y={119.87}
          rx={1.3}
          ry={1.3}
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeMiterlimit: 10,
            strokeWidth: ".25px",
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={142.89}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={148.11}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={44.21}
          height={3.96}
          x={23.6}
          y={153.32}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={91.78}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={56.97}
          height={6.33}
          x={107.48}
          y={80.31}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={79.13}
          height={41.15}
          x={102.62}
          y={73.98}
          rx={1.3}
          ry={1.3}
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeMiterlimit: 10,
            strokeWidth: ".25px",
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={97}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={107.48}
          y={102.21}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={44.21}
          height={3.96}
          x={107.48}
          y={107.43}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={91.78}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={56.97}
          height={6.33}
          x={23.6}
          y={80.31}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={79.13}
          height={41.15}
          x={18.75}
          y={73.98}
          rx={1.3}
          ry={1.3}
          style={{
            fill: "none",
            stroke: "currentColor",
            strokeMiterlimit: 10,
            strokeWidth: ".25px",
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={97}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={69.63}
          height={3.96}
          x={23.6}
          y={102.21}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <rect
          width={44.21}
          height={3.96}
          x={23.6}
          y={107.43}
          rx={0.39}
          ry={0.39}
          style={{
            fill: "currentColor",
            strokeWidth: 0,
          }}
        />
        <path
          d="M0 0h200v200H0z"
          style={{
            fill: "none",
            strokeWidth: 0,
          }}
        />
      </svg>
      <p className="text-xs font-bold">{_("title")}</p>
    </>
  )
}
