import { Menu, useMenu } from "@/components/collection"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePersistedState } from "@/hooks/usePersistedState"
import { useSelectItem } from "@/hooks/useSelect"
import { MediasFile } from "@/store/medias/localizers"
import { PlusSquare, Scan, Trash, UserIcon } from "lucide-react"

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "users-view",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: async () => ({ error: false }),
    dictionary: "pages.dashboard.users.delete-confirm",
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => id,
    onAsyncConfirm: async () => ({ error: false }),
    dictionary: "",
  })

  // dialogs
  const { setItem: updateAccount, ...accountProps } = useDialog<User>()
  const { setItem: updateProfile, ...profileProps } = useDialog<User>()
  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        confirmDelete,
        create,
        confirmDeleteSelection,
      }}
    >
      {children}
      <Confirm {...deleteSelectionProps} />
      <Confirm {...deleteProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { partner: Partner }
export const ContextMenu: React.FC<ContextMenuProps> = ({ partner }) => {
  const { create, confirmDelete, confirmDeleteSelection } = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, partner.id)
  const { id } = partner

  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        <Scan aria-hidden />
        {isSelected ? "Unselect partner" : "Select partner"}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/patners/${id}`)}>
        <UserIcon aria-hidden />
        Display partner
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        Delete partner
      </Menu.Item>
      <Menu.Separator />
      <Menu.Item onClick={create}>
        <PlusSquare aria-hidden />
        Create a partner
      </Menu.Item>
      {type === "context-menu" && isSelected && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={confirmDeleteSelection}>
            <Trash aria-hidden />
            Delete selection
          </Menu.Item>
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  create: () => void
  confirmDeleteSelection: () => void
}

/**
 * placeholders types
 */
export type Partner = {
  id: string
  name: string
  users: ById<PartnerUser>
  facilitiies: ById<PartnerFacilitiy>
}
export type PartnerFacilitiyBase = {
  id: string
  name: string
  description: string
  phones: ExtraField[]
  emails: ExtraField[]
  address: Address
  website: ExtraField[]
  logo: MediasFile
  createdAt: Date
  updatedAt: Date
}
export type PartnerAccomodation = Extend<
  PartnerFacilitiyBase,
  {
    type: "accomodation"
    stars: number
    numberOfRooms: number
    roomsType: ById<AccomodationRoom>
  }
>
export type PartnerVenues = Extend<
  PartnerFacilitiyBase,
  {
    type: "venues"
    rooms: ById<VenueRoom>
  }
>
export type PartnerEventAgencies = Extend<
  PartnerFacilitiyBase,
  {
    type: "event-agencies"
  }
>
export type PartnerEventServices = Extend<
  PartnerFacilitiyBase,
  {
    type: "event-services"
  }
>
export type PartnerTransportations = Extend<
  PartnerFacilitiyBase,
  {
    type: "transportations"
  }
>
export type PartnerFacilitiy =
  | PartnerAccomodation
  | PartnerVenues
  | PartnerEventAgencies
  | PartnerEventServices
  | PartnerTransportations

export type PartnerUser = Extend<
  User,
  {
    partnerId: string
  }
>
export type AccomodationRoom = {
  id: string
  name: string
  images: ById<MediasFile>
}
export type VenueRoom = {
  id: string
  name: string
  images: ById<MediasFile>
}

/**
 * to gpt
 */
type User = {
  id: string
  email: string
  status: UserStatus
  role: UserRole
  profile: UserProfile
  deletedAt: Date | null
  lastLoginAt: Date | null
  createdAt: Date
  updatedAt: Date
}
type UserRole = "member" | "admin"
type UserStatus = "pending" | "active" | "deleted" | "suspended"
type UserProfile = {
  dob: Date | null
  image: string | null
  preview: string | null
  thumbnail: string | null
  firstname: string
  lastname: string
  position: string
  company: string
  phones: ExtraField[]
  emails: ExtraField[]
  address: Address
  extras: ExtraField[]
}
type Address = {
  address: string
  city: string
  state: string
  zip: string
  country: string
}
type ExtraField = {
  value: string
  name: string
}
