import { PageContent } from "@/components/layout/dashboard"
import { Grid, Selection } from "@/components/collection"
import { Toolbar } from "@/components/collection/toolbar"
import { Button } from "@/components/ui/button"

import { dictionarySafe, useDictionary } from "@/dictionaries/hooks"
import { useIsAdmin } from "@/store/auth/hooks"
import { useFilteredLanguages } from "@/store/languages/hooks"
import { Check, Trash } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { filterable, sortable, matchable, filtered } = useFilteredLanguages()
  const { view, setView, create, selected, setSelected, confirmDeleteSelection } = usePageContext()
  const isAdmin = useIsAdmin()
  return (
    <PageContent>
      {isAdmin && (
        <Selection
          selected={selected}
          unselect={() => setSelected([])}
          deleteSelection={confirmDeleteSelection}
        />
      )}
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Button onClick={create}>{_("create")}</Button>
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={dictionarySafe(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Grid {...{ view }}>
        {A.map([...filtered], language => (
          <Item key={language.id} language={language} />
        ))}
      </Grid>
    </PageContent>
  )
}

/**
 * Filters
 */
type Props = ReturnType<typeof useFilteredLanguages>["filterable"]
const Filters: React.FC<Props> = ({ toggle, isActive }) => {
  const { _ } = useDictionary(dictionary("filters"))
  return (
    <Toolbar.Filters>
      <Toolbar.Filters.Button onClick={() => toggle("soft-deleted")}>
        {isActive("soft-deleted") ? (
          <>
            <Trash aria-hidden />
            {_("soft-deleted-true")}
          </>
        ) : (
          <>
            <Check aria-hidden />
            {_("soft-deleted-false")}
          </>
        )}
      </Toolbar.Filters.Button>
    </Toolbar.Filters>
  )
}
