import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { contextMapper } from "@/dictionaries/helpers"
import { ValidContextAndProps, useDictionary } from "@/dictionaries/hooks"
import { ConfirmDialogProps, confirmable, createConfirmation } from "react-confirm"
import { useConfirm } from "./hooks/useConfirm"

/**
 * dictionary src/dictionaries/en/components/ui/confirm.json
 */
export type ConfirmDictionary = {
  title: string
  description: string
  confirm?: string
  cancel?: string
  success: string
  error: string
  progress: string
}
export const confirmSafeDictionary = <T extends string>(
  context: ValidContextAndProps<T, ConfirmDictionary>
) => context

/**
 * Confirm
 */
type Props = Omit<ReturnType<typeof useConfirm<any>>, "confirm"> & {
  children?: React.ReactNode
}
export const Confirm: React.FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  onAsyncConfirm,
  list,
  displayName = () => "",
  dictionary,
  children,
}) => {
  const { _ } = useDictionary(contextMapper("components", "ui", "confirm"))
  const { _: translate } = useDictionary<string, false>(dictionary)

  const onClick = async () => {
    if (open === false) return
    if (G.isNotNullable(onConfirm)) onConfirm(open)
    if (G.isNotNullable(onAsyncConfirm)) {
      if (list) {
        let counter = 1
        const total = list.length
        const toastId = toast.promise(
          Promise.all(
            A.mapWithIndex(list, async (index, item) => {
              if ((await onAsyncConfirm(item)).error)
                toast.error(translate("error"), { description: displayName(item) })
              toast.loading(translate("progress", { counter: counter++, total }), {
                id: toastId,
              })
            })
          ),
          {
            loading: translate("progress", { counter, total }),
            success: translate("success"),
          }
        )
      } else {
        const toastId = toast.loading(translate("progress"))
        if ((await onAsyncConfirm(open)).error) toast.error(translate("error"), { id: toastId })
        else toast.success(translate("success"), { id: toastId })
      }
    }
  }
  return (
    <Dialog.Root open={open !== false} onOpenChange={onCancel} modal>
      <Dialog.Content hideClose size="max-w-md">
        <Dialog.Header>
          <Dialog.Title>{translate("title")}</Dialog.Title>
          {S.isNotEmpty(translate("description", { defaultValue: "" })) && (
            <Dialog.Description>{translate("description")}</Dialog.Description>
          )}
        </Dialog.Header>
        {G.isNotNullable(children) && <Dialog.Content>{children}</Dialog.Content>}
        <Dialog.Footer>
          <Button variant={"outline"} onClick={onCancel}>
            {translate("cancel", { defaultValue: _("cancel") })}
          </Button>
          <Button onClick={onClick}>{translate("confirm", { defaultValue: _("confirm") })}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

/**
 * Confirmable
 * dictionary src/dictionaries/en/components/ui/confirm.json
 */
type ConfirmableProps = {
  dictionary: string
}
const Confirmable: React.FC<ConfirmDialogProps<ConfirmableProps, boolean>> = ({
  dictionary,
  proceed,
  show,
}) => {
  const { _ } = useDictionary("components.ui.confirm")
  const { _: translate } = useDictionary<string, false>(dictionary)
  return (
    <Dialog.Root open={show} onOpenChange={() => proceed(false)} modal>
      <Dialog.Content hideClose size="max-w-md">
        <Dialog.Header>
          <Dialog.Title>{translate("title")}</Dialog.Title>
          {S.isNotEmpty(translate("description", { defaultValue: "" })) && (
            <Dialog.Description>{translate("description")}</Dialog.Description>
          )}
        </Dialog.Header>
        <Dialog.Footer>
          <Button variant={"outline"} onClick={() => proceed(false)}>
            {translate("cancel", { defaultValue: _("cancel") })}
          </Button>
          <Button onClick={() => proceed(true)}>
            {translate("confirm", { defaultValue: _("confirm") })}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

/**
 * confirmAlert
 * example:
 * const isConfirm = await confirmAlert({ dictionary: "pages." })
 */
export const confirmAlert = createConfirmation(confirmable(Confirmable))
