import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormReorderableItem,
  FormReorderableList,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormToggle,
  FormTranslationTabs,
  useFormContext,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { textPlaceholder } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { RectangleHorizontal, Trash } from "lucide-react"
import { match } from "ts-pattern"
import { useFieldGroupContext, useForm } from "use-a11y-form"
import { v4 as uuid } from "uuid"
import { proseStyle } from "../../frontend/proseStyle"
import { useCornerOptions } from "../../hooks/useCornerOptions"
import { useSideOptions } from "../../hooks/useSideOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)
const formDictionary = createContextMapper("components", "cms", "content", "form")

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(formDictionary())
  const _errors = useErrorsDictionary()

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      tabs: item.props.tabs,
      translations: D.map(languagesById, language => ({
        languageId: language.id,
        tabs:
          translate(item, language)?.props.tabs ??
          A.reduce(item.props.tabs, {} as Tabs, (tabs, tab) => ({
            ...tabs,
            [tab]: emptyTab,
          })),
      })),
    })),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          tabs: values.tabs,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: A.reduce(D.values(values.translations), [] as string[], (files, translation) => [
          ...files,
          ...A.filterMap(D.values(translation.tabs), tab => tab.image || O.None),
          ...A.filterMap(D.values(translation.tabs), tab => tab.icon || O.None),
        ]),
      }
      match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTabs />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * FormTabs
 */
const FormTabs: React.FC = () => {
  const { _ } = useDictionary(dictionary("tabs"))
  const { _: _form } = useDictionary(formDictionary())

  const { values, setValues } = useFormContext<FormTabsContext>()

  // create a new tab
  const createTab = () => {
    const tabId = uuid()
    setValues({
      tabs: [...values.tabs, tabId],
      translations: D.map(values.translations, translation => ({
        ...translation,
        tabs: {
          ...translation.tabs,
          [tabId]: emptyTab,
        },
      })),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = values.tabs.indexOf(active.id as string)
      const newIndex = values.tabs.indexOf(over!.id as string)
      setValues({
        tabs: arrayMove(values.tabs, oldIndex, newIndex),
      })
    }
  }

  return (
    <FormFieldGroup name="tabs">
      <div className="flex flex-col items-start gap-4">
        <FormHeader>
          <FormHeader.Title>{_("title")}</FormHeader.Title>
          <FormHeader.Description>{_("description")}</FormHeader.Description>
        </FormHeader>
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={values.tabs}
          createButton={_form("create-tab")}
          create={createTab}
        >
          {A.map(values.tabs, id => (
            <FormTab id={id} key={id} />
          ))}
        </FormReorderableList>
      </div>
    </FormFieldGroup>
  )
}

/**
 * FormTab
 */
const FormTab: React.FC<{ id: string }> = ({ id }) => {
  const { _ } = useDictionary(dictionary("tabs"))
  const { _: _form } = useDictionary(formDictionary())
  const { id: contextKey } = useCmsContext()
  const { values, setValues } = useFormContext<FormTabsContext>()
  const { title } = D.getUnsafe(
    useFieldGroupContext<FormTabsContext["translations"][string]["tabs"]>().values,
    id
  )

  // delete a tab
  const deleteTab = () => {
    setValues({
      tabs: A.reject(values.tabs, tab => tab === id),
      translations: D.map(values.translations, translation => ({
        ...translation,
        tabs: D.deleteKey(translation.tabs, id),
      })),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") => {
    const oldIndex = values.tabs.indexOf(id)
    switch (keyCode) {
      case "ArrowUp": {
        const newIndex = oldIndex - 1
        if (newIndex < 0) return
        setValues({
          tabs: arrayMove(values.tabs, oldIndex, newIndex),
        })
        break
      }
      case "ArrowDown": {
        const newIndex = oldIndex + 1
        if (newIndex >= values.tabs.length) return
        setValues({
          tabs: arrayMove(values.tabs, oldIndex, newIndex),
        })
        break
      }
    }
  }
  const sideOptions = useSideOptions()
  const cornerOptions = useCornerOptions()
  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <RectangleHorizontal size={16} aria-hidden />
          {textPlaceholder(title, _form("tab-title"))}
        </>
      }
      titleLevel={4}
      actions={
        <Button
          variant="secondary"
          size="xxs"
          icon
          onClick={deleteTab}
          aria-label={_form("delete-tab")}
        >
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
    >
      <FormFieldGroup name={id}>
        <div className="relative flex flex-col gap-6 col-span-3">
          <FormInput
            label={_("tab.title-label")}
            name="title"
            placeholder={_("tab.title-placeholder")}
          />
          <FormMediasImage
            label={_("tab.icon-label")}
            name="icon"
            fit="object-contain"
            ratio="aspect-video"
            contextKey={contextKey}
          />
          <FormTiptap
            label={_("tab.content-label")}
            name="content"
            prose={cx(proseStyle, "bg-white")}
          />
          <FormMediasImage
            label={_("tab.image-label")}
            name="image"
            fit="object-contain"
            ratio="aspect-video"
            contextKey={contextKey}
          />
          <FormSelect label={_form("side-label")} name="imageSide" options={sideOptions} />
          <div className="grid grid-cols-2 gap-6">
            <FormToggle
              label={_form("corner-decoration-label")}
              name="cornerDecoration"
              options={cornerOptions}
              className="grid grid-cols-2 w-max"
              variant="outline"
              icon
            />
            <FormToggle
              label={_form("corner-clip-label")}
              name="cornerClip"
              options={cornerOptions}
              className="grid grid-cols-2 w-max"
              variant="outline"
              icon
            />
          </div>
        </div>
      </FormFieldGroup>
    </FormReorderableItem>
  )
}

/**
 * helpers
 */
const emptyTab = {
  title: "",
  image: null,
  content: "",
  icon: null,
  imageSide: "right",
  cornerDecoration: [],
  cornerClip: [],
}

/**
 * types
 */
type Tabs = FormPayload<ItemType>["translations"][number]["props"]["tabs"]
type FormTabsContext = {
  tabs: string[]
  translations: Record<
    string,
    {
      languageId: string
      tabs: Record<
        string,
        {
          title: string
          content: string
          image: string | null
          icon: string | null
          imageSide: string
          cornerDecoration: string[]
          cornerClip: string[]
        }
      >
    }
  >
}
