import { useActiveLanguages, useCurrentLanguage } from "@/store/languages/hooks"
import { Language } from "@/store/languages/localizers"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { someErrors, someTouched, useFormContext } from "use-a11y-form"
import { FormFieldGroup } from "./field-group"

/**
 * Tabs
 */
type Props = {
  className?: ClassName
  children: (language: Language) => React.ReactNode
  context?: boolean
  isTouch?: (id: string) => boolean
  hasErrors?: (id: string) => boolean
}
export const FormTranslationTabs: React.FC<Props> = ({
  className,
  children,
  context = true,
  ...props
}) => {
  const current = useCurrentLanguage()
  const languages = useActiveLanguages()
  const formCtx = useFormContext<
    Record<string, unknown> & { translations: Record<string, Record<string, unknown>> }
  >()
  const isTouch =
    props.isTouch ||
    ((id: string) => {
      return someTouched(formCtx.touched.translations[id] ?? {})
    })
  const hasErrors =
    props.hasErrors ||
    ((id: string) => {
      return someErrors(formCtx.errors.translations[id] ?? {})
    })

  return context ? (
    <FormFieldGroup name="translations">
      <TabsPrimitive.Root
        defaultValue={current?.id}
        className={cx(translationTabsCx.root, className)}
      >
        <TabsPrimitive.List className={translationTabsCx.list}>
          {A.map(languages, ({ id, name }) => (
            <TabsPrimitive.Trigger key={id} value={id} className={translationTabsCx.trigger}>
              {hasErrors(id) ? (
                <span className={translationTabsCx.errorDot} aria-hidden />
              ) : isTouch(id) ? (
                <span className={translationTabsCx.editDot} aria-hidden />
              ) : null}
              {name}
            </TabsPrimitive.Trigger>
          ))}
        </TabsPrimitive.List>
        {A.map(languages, language => (
          <TabsPrimitive.Content
            key={language.id}
            value={language.id}
            className={translationTabsCx.content}
          >
            <FormFieldGroup name={language.id}>{children(language)}</FormFieldGroup>
          </TabsPrimitive.Content>
        ))}
      </TabsPrimitive.Root>
    </FormFieldGroup>
  ) : (
    <TabsPrimitive.Root
      defaultValue={current?.id}
      className={cx(translationTabsCx.root, className)}
    >
      <TabsPrimitive.List className={translationTabsCx.list}>
        {A.map(languages, ({ id, name }) => (
          <TabsPrimitive.Trigger key={id} value={id} className={translationTabsCx.trigger}>
            {hasErrors(id) ? (
              <span className={translationTabsCx.errorDot} aria-hidden />
            ) : isTouch(id) ? (
              <span className={translationTabsCx.editDot} aria-hidden />
            ) : null}
            {name}
          </TabsPrimitive.Trigger>
        ))}
      </TabsPrimitive.List>
      {A.map(languages, language => (
        <TabsPrimitive.Content
          key={language.id}
          value={language.id}
          className={translationTabsCx.content}
        >
          {children(language)}
        </TabsPrimitive.Content>
      ))}
    </TabsPrimitive.Root>
  )
}

export const translationTabsCx = {
  root: "-mx-3",
  list: "flex items-center justify-end w-full h-8 px-4 rounded-md text-muted-foreground",
  trigger: cx(
    "inline-flex items-center justify-center px-3 py-1.5 gap-2 rounded-sm",
    "text-sm font-medium whitespace-nowrap",
    "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-50",
    "data-[state=active]:bg-secondary data-[state=active]:text-foreground data-[state=active]:shadow-sm",
    "transition-all"
  ),
  editDot: "inline-block bg-blue-500 rounded-full w-2 h-2",
  errorDot: "inline-block bg-destructive rounded-full w-2 h-2",
  content:
    "px-3 py-3 my-3 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
}
