import { ThemeProvider } from "@/components/layout/dashboard/theme/context"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { Tooltip } from "@/components/ui/tooltip"

import { Menu } from "lucide-react"
import { Breadcrumb, Breadcrumbs } from "./breadcrumbs"
import { Sidebar } from "./sidebar"
import { SidebarContext, SidebarProvider } from "./sidebar/context"
import { UserDropdown } from "./user-dropdown"

/**
 * Layout
 */
type LayoutProps = {
  children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <SidebarProvider>
        <div className="relative grid min-h-screen dark:bg-black bg-white dark:bg-grid-white/[0.04] bg-grid-black/[0.04]">
          <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
          <Sidebar />
          {children}
        </div>
      </SidebarProvider>
    </ThemeProvider>
  )
}
export default Layout

/**
 * PageWrapper
 */
export const PageWrapper = React.forwardRef<HTMLDivElement, React.ComponentProps<"main">>(
  ({ className, ...props }, ref) => {
    const sidebar = React.useContext(SidebarContext)
    return (
      <main
        ref={ref}
        className={cx(
          "md:transition-all md:ease-in-out md:duration-300 md:transform pr-4 pt-4 pl-4 pb-4",
          sidebar.state ? "md:pl-[320px]" : "md:pl-24",
          className
        )}
        {...props}
      />
    )
  }
)

/**
 * PageContent
 */
export const PageContent = React.forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cx(className)} {...props} />
  }
)

/**
 * TempToggle
 */
export const SidebarToggle: React.FC = () => {
  const { _ } = useDictionary("components.layout.sidebar")
  const sidebar = React.useContext(SidebarContext)
  return (
    <Tooltip side="left" content={_("button-toggle")}>
      <Button onClick={sidebar.toggle} variant="secondary" icon>
        <Menu size={16} aria-hidden />
        <SrOnly>{_("button-toggle")}</SrOnly>
      </Button>
    </Tooltip>
  )
}

/**
 * PageHeader
 */
type PageHeaderProps = {
  breadcrumbs: Breadcrumb[]
  syntheticBreadcrumbs?: React.ReactNode
  image?: string
  children?: React.ReactNode
}
export const PageHeader: React.FC<PageHeaderProps> = ({ breadcrumbs, image, children }) => {
  return (
    <>
      {G.isNotNullable(image) && (
        <div
          className={cx(
            "absolute top-0 inset-x-0 -z-50 h-24 bg-cover bg-no-repeat bg-center opacity-1"
          )}
          style={{ backgroundImage: `url('${image}')` }}
        />
      )}
      <div className="flex justify-between items-center pb-4 gap-2">
        <SidebarToggle />
        <Breadcrumbs {...{ breadcrumbs }} className="grow" />
        <div className="flex gap-2">
          {G.isNotNullable(children) && <div>{children}</div>}
          <UserDropdown align="end" side="bottom" />
        </div>
      </div>
    </>
  )
}
