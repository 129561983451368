import { useInterval } from "@/hooks/useInterval"
import { service } from "@/services/trackings/service"
import { formatISO } from "date-fns"
import { StatsPieChart } from "./stats-pie-chart"
import { StatRecord, StatsProps } from "../types"
import { toRechartsData } from "../helpers"

const reloadTime = 30000

/**
 * StatsDevice
 */
export const StatsDevice: React.FC<StatsProps> = ({
  trackingId,
  setIsLoading = () => true,
  interval,
  live,
}) => {
  const [data, setData] = React.useState<StatRecord[]>([])
  const updateData = async () => {
    setIsLoading(true)
    const params = interval.from
      ? { from: formatISO(interval.from), to: formatISO(interval.to) }
      : { to: formatISO(interval.to) }
    const { error, data } = G.isNullable(trackingId)
      ? await service.byDevice(params)
      : await service.trackings.byDevice(trackingId, params)
    setIsLoading(false)
    if (error) return
    setData(toRechartsData(data.stats))
  }
  React.useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval.from, interval.to])
  useInterval(updateData, live ? reloadTime : null)
  return <StatsPieChart stats={data} />
}
