import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useCmsContext } from "../../Context"
import { proseStyle } from "../../frontend/proseStyle"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _form } = useDictionary(contextMapper("components", "cms", "content", "form"))
  const _errors = useErrorsDictionary()

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languagesById, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
        description: translate(item, language)?.props.description ?? "",
        linkText: translate(item, language)?.props.linkText ?? "",
        linkUrl: translate(item, language)?.props.linkUrl ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      const payload: FormPayload<ItemType> = {
        props: {
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }
      match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput
                label={_form("title-label")}
                name="title"
                placeholder={_form("title-placeholder")}
              />
              <FormInput
                label={_form("secondary-label")}
                name="secondary"
                placeholder={_form("secondary-placeholder")}
              />
              <FormTiptap
                label={_form("description-label")}
                name="description"
                prose={cx(proseStyle, "bg-white")}
              />
              <FormInput
                label={_("link-text-label")}
                name="linkText"
                placeholder={_("link-text-placeholder")}
              />
              <FormInput
                label={_("link-url-label")}
                name="linkUrl"
                type="url"
                placeholder={_("link-url-placeholder")}
              />
            </FormTranslationContext>
            <FormHeader>
              <FormHeader.Title>{_form("more-options-title")}</FormHeader.Title>
              <FormHeader.Description>{_form("more-options-description")}</FormHeader.Description>
            </FormHeader>
            <FormSelect
              label={_form("title-level-label")}
              name="titleLevel"
              options={titleLevelOptions}
            />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
