import { z } from "zod"
import { apiAddress, apiExtraField, apiSingleImage } from "../commons/schemas"

/**
 * ApiUserProfile
 */
export const apiUserProfile = z.object({
  firstname: z.string(),
  lastname: z.string(),
  image: apiSingleImage,
  dob: z.string().nullable(),
  position: z.string(),
  company: z.string(),
  phones: apiExtraField.array(),
  emails: apiExtraField.array(),
  address: apiAddress,
  extras: apiExtraField.array(),
})
export type ApiUserProfile = z.infer<typeof apiUserProfile>

/**
 * ApiUser
 */
export const apiUserRole = z.union([
  z.literal("member"),
  z.literal("admin"),
  z.literal("superadmin"),
])
export type ApiUserRole = z.infer<typeof apiUserRole>
export const apiUserStatus = z.union([
  z.literal("pending"),
  z.literal("active"),
  z.literal("deleted"),
  z.literal("suspended"),
])
export type ApiUserStatus = z.infer<typeof apiUserStatus>
export const apiUser = z.object({
  id: z.string().uuid(),
  email: z.string().optional(),
  status: apiUserStatus,
  role: apiUserRole,
  profile: apiUserProfile,
  deletedAt: z.string().nullable(),
  lastLoginAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiUser = z.infer<typeof apiUser>
