/**
 * strings helpers
 */

/**
 * localeCompare
 */
export const localeCompare = (a: string, b: string) => a.localeCompare(b)

/**
 * Pads a string value with leading zeroes until length is reached
 * ? Example: zeroPad(8, 3) => "008"
 * @param {string | number} value
 * @param {number} [length = 2]
 * @returns {string}
 */
export const zeroPad = (value: string | number, length = 2): string => `${value}`.padStart(length, "0")

/**
 * prependHttp
 */
export const prependHttp = (url: string, https = true): string => {
  if (/^\.*\/|^(?!localhost)\w+?:/.test(url.trim())) return url.trim()
  return url.trim().replace(/^(?!(?:\w+?:)?\/\/)/, https ? "https://" : "http://")
}
/**
 * string conversion
 */
export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, (c) => "_" + c.toLowerCase())
export const camelToKebab = (str: string) => str.replace(/[A-Z]/g, (c) => "-" + c.toLowerCase())
export const camalize = (string: string): string =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase())

/**
 * remove https
 */
export const readableURL = S.replaceByRe(/(^\w+:|^)\/\//, "")

/**
 * Capitalize the first character of a string
 * @param {string} string
 * @return {string}
 */
export const capitalize = (s: string) => `${S.toUpperCase(S.slice(s, 0, 1))}${S.sliceToEnd(s, 1)}`
export const ucFirst = capitalize

/**
 * pluralize
 */
export const pluralize = (n: number, word: string, none?: string) =>
  n === 0 ? `${none ?? ""} ${word}` : n === 1 ? `${n} ${word}` : `${n} ${word}s`

/**
 * incrementName
 */
export const incrementName = (name: string, existingNames: string[]) => {
  if (existingNames.length === 0) return name
  const numbers = existingNames.map((name) => +name.replace(/[^0-9]/g, ""))
  const nextNumber = Math.max(0, ...numbers) + 1
  const repName = name.replace(/[0-9]/g, "")
  return `${repName} ${nextNumber}`
}

/**
 * makeBreakable
 */
export const makeBreakable = (str: string, interval = 5): string =>
  str.replace(new RegExp(`(.{${interval}})`, "g"), `$1\u200B`)

/**
 * truncateText
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) return text.substring(0, maxLength) + "..."
  return text
}

/**
 * truncateMiddle
 */
export const truncateMiddle = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text
  const charsToShow = maxLength - 3 // 3 pour les points de suspension
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)
  return text.substring(0, frontChars) + "..." + text.substring(text.length - backChars)
}

/**
 * textPlaceholder
 */
export const textPlaceholder = <T extends string | undefined, U extends string>(
  value: U,
  placeholder?: T
): U | (T extends undefined ? null : T) => {
  if (S.isNotEmpty(S.trim(value ?? ""))) return value as U
  if (G.isNotNullable(placeholder)) return placeholder as T extends undefined ? null : T
  return null as T extends undefined ? null : T
}

/**
 * slugify
 */
export const slugify = (value: string) => {
  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}
