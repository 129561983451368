import imageTeamSrc from "@/assets/images/team.png"
import { Grid } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { StatsVisitByDays } from "@/components/trackings/components/stats-visit-by-days"
import { Card } from "@/components/ui/card"
import { useDocumentVisibility } from "@/hooks/useDocumentVisibility"
import { useInterval } from "@/hooks/useInterval"
import { service } from "@/services/trackings/service"
import millify from "millify"
import { useCountUp } from "use-count-up"

/**
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "index")

/**
 * Page: dashboard/index
 */
const DashboardIndex: React.FC = () => {
  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[]} />

      <PageContent>
        <Grid view="grid">
          <LastVisits />
          <Card.Spotlight className="flex flex-col">
            <Card.Header className="flex flex-col grow">
              <div
                className="w-full grow mb-4 opacity-95 bg-cover bg-center-top rounded-t-md"
                style={{ backgroundImage: `url('${imageTeamSrc}'` }}
              />
              <Card.Title>The team</Card.Title>
            </Card.Header>
            <Card.Content>
              <p className="text-sm font-light text-foreground/75">
                The Business Events Luxembourg team, led by François Lafont, includes experts in
                administration, corporate events, incentives, and team building, as well as
                collaboration with international associations. Their diverse expertise ensures the
                success of each event.
              </p>
            </Card.Content>
          </Card.Spotlight>
          <Card.Spotlight className="min-h-52"></Card.Spotlight>
        </Grid>
      </PageContent>
    </PageWrapper>
  )
}
export default DashboardIndex

/**
 * LastVisits
 */
export const LastVisits: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const refreshDelay = 30000
  const isVisible = useDocumentVisibility()
  const [data, setData] = React.useState<LastVisitsStats>({
    today: 16,
    last7Days: 0,
    lastMonth: 0,
  })
  const updateData = async () => {
    const { error, data } = await service.stats()
    if (error) return
    setData({
      today: D.get(data.stats, "today") ?? 0,
      last7Days: D.get(data.stats, "last7Days") ?? 0,
      lastMonth: D.get(data.stats, "lastMonth") ?? 0,
    })
  }
  React.useEffect(() => {
    updateData()
  }, [])
  useInterval(updateData, isVisible ? refreshDelay : null)
  const interval = React.useMemo(() => {
    const now = new Date()
    return { from: T.sub(now, { days: 7 }), to: now }
  }, [])

  return (
    <Card.Spotlight>
      <Card.Header>
        <Card.Title>{_("last-visits.title")}</Card.Title>
      </Card.Header>
      <Card.Content className="flex flex-col gap-6">
        <div className="w-full aspect-video">
          <StatsVisitByDays
            hideAxis
            hideGrid
            strokeWidth={3}
            color="hsl(var(--primary))"
            live={isVisible}
            interval={interval}
          />
        </div>
        <div className="grid grid-cols-3 gap-6">
          <LastVisitsHighlight counter={data.today} description={_("last-visits.today")} />
          <LastVisitsHighlight
            counter={data.last7Days}
            description={_("last-visits.last-7-days")}
          />
          <LastVisitsHighlight counter={data.lastMonth} description={_("last-visits.last-month")} />
        </div>
      </Card.Content>
    </Card.Spotlight>
  )
}
export const LastVisitsHighlight: React.FC<{ counter: number; description: string }> = ({
  counter: count,
  description,
}) => {
  const { value } = useCountUp({
    isCounting: React.useMemo(() => count > 0, [count]),
    end: count,
    duration: N.clamp(Math.round(count * 0.325), 0, 5),
    formatter: v => millify(v, { precision: 0 }),
  })

  return (
    <div className="relative z-20 flex flex-col justify-center items-center aspect-square dark:bg-black/50 bg-white border rounded-md shadow">
      <span className="text-2xl @xs/card:text-3xl @sm/card:text-4xl @md/card:text-5xl @lg/card:text-6xl font-medium text-primary">
        {value}
      </span>
      <span className="text-[10px] @xs/card:text-xs @lg/card:text-sm text-foreground/50">
        {description}
      </span>
    </div>
  )
}
type LastVisitsStats = { today: number; last7Days: number; lastMonth: number }
