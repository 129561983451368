import { RouteAuthenticated } from "@/components/routes/authenticated"
import { RouteUnauthenticated } from "@/components/routes/unauthenticated"
import Index from "@/pages"
import ValidateEmail from "@/pages/validate-email"
import ForgotPassword from "@/pages/forgot-password"
import ResetPassword from "@/pages/reset-password"
import DashboardRoutes from "@/routes/dashboard"
import { Redirect, Switch, Route } from "wouter"

/**
 * Routes
 */
const Routes: React.FC = () => (
  <Switch>
    <RouteAuthenticated path="/dashboard/:rest*" redirect="/">
      <DashboardRoutes />
    </RouteAuthenticated>
    <RouteUnauthenticated path="/" redirect="/dashboard">
      <Index />
    </RouteUnauthenticated>
    <Route path="/validate-email/:token">
      {({ token }) => <ValidateEmail token={token ?? ""} />}
    </Route>
    <RouteUnauthenticated path="/forgot-password" redirect="/dashboard">
      <ForgotPassword />
    </RouteUnauthenticated>
    <Route path="/reset-password/:token">
      {({ token }) => <ResetPassword token={token ?? ""} />}
    </Route>
    <Redirect to="/" />
  </Switch>
)
export default Routes
