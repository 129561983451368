import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import globalConfig from "@/config/global"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { forgotPassword } from "@/store/auth/actions"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/pages/forgot-password.json
 */
const dictionary = createContextMapper("pages", "forgot-password")

/**
 * Page: ForgotPassword
 */
const ForgotPassword: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const [requestDone, setRequestDone] = React.useState(false)
  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({ email: "" })),
    validate: validator({
      email: [min(1, _("email-required")), isEmail(_("email-format"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _("VALIDATION_FAILURE")
      const payload = {
        email: values.email,
        redirect: `${globalConfig.host}/reset-password`,
      }
      return match(await forgotPassword(payload))
        .with({ error: false }, () => navigate("/dashboard"))
        .otherwise(({ code }) =>
          match(code)
            .with("USER_NOT_FOUND", () => setRequestDone(true))
            .otherwise(_)
        )
    },
  })
  return (
    <div className="flex justify-center items-center w-full min-h-screen p-8 bg-secondary">
      {requestDone ? (
        <Card className="w-full max-w-xs">
          <Card.Header>
            <Card.Title>{_("success-title")}</Card.Title>
          </Card.Header>
          <Card.Content>
            <div className="grid gap-4">
              <p className="text-sm">{_("success-message")}</p>
              <Button variant="ghost" onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </div>
          </Card.Content>
        </Card>
      ) : (
        <Card className="w-full max-w-xs">
          <Card.Header>
            <Card.Title>{_("title")}</Card.Title>
            <Card.Description>{_("secondary")}</Card.Description>
          </Card.Header>
          <Card.Content>
            <Form form={form} className="grid gap-4">
              <FormAssertive />
              <FormInput
                label={_("email-label")}
                name="email"
                type="email"
                placeholder={_("email-placeholder")}
              />
              <FormSubmit>{_("submit")}</FormSubmit>
              <Button variant="ghost" onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </Form>
          </Card.Content>
        </Card>
      )}
    </div>
  )
}
export default ForgotPassword
