import { ItemMappingExport } from "../schemas"
import { type ItemType, itemType } from "./schemas"
import { useTranslation } from "@/store/languages/hooks"
import { Skeleton } from "@/components/ui/skeleton"
//
//

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
// const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { title } = t(item).props
  return (
    <div className="pt-4 font-museo">
      <h2 className="flex text-be-title text-be-midnight uppercase">
        {title}
        <Skeleton className="w-[350px]" value={title} />
      </h2>
    </div>
  )
}
