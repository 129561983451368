import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Context, ContextProvider } from "./Context"
import { Collection } from "./Collection"

/**
 * Page: RFP
 */
const RFP: React.FC = () => {
  const select = useSelect(Context)
  return (
    <PageWrapper>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[["RFP", "/dashboard/rfp"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <RFP />
  </ContextProvider>
)
