import { byId } from "@/fns/byId"
import * as Medias from "@/services/medias/service"
import { match } from "ts-pattern"
import { mediasStore } from "."
import { getChildrenFolderIds, isMediaInFolder, isMediaInRoot } from "./helpers"
import { MediasFile, MediasFolder, localizeMediaFile, localizeMediaFolder } from "./localizers"

/**
 * initMedias
 */
export const initMedias = async () =>
  match(await Medias.service.folders.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ folders: byId(data.folders, localizeMediaFolder), initDone: true })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getMediasRoot
 */
export const getMediasRoot = async () =>
  match(await Medias.service.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        folders: flow(D.reject(isMediaInRoot), D.merge(byId(data.folders, localizeMediaFolder))),
        files: flow(D.reject(isMediaInRoot), D.merge(byId(data.files, localizeMediaFile))),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getMediasFolders
 */
export const getMediasFolders = async () =>
  match(await Medias.service.folders.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ folders: byId(data.folders, localizeMediaFolder) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getMediasFolder
 */
export const getMediasFolder = async (folderId: string) =>
  match(await Medias.service.folders.read(folderId))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        folders: flow(
          D.reject(isMediaInFolder(folderId)),
          D.merge(byId([data.folder, ...data.folder.folders], localizeMediaFolder))
        ),
        files: flow(
          D.reject(isMediaInFolder(folderId)),
          D.merge(byId(data.folder.files, localizeMediaFile))
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createMediasFolder
 */
export const createMediasFolder = async (payload: Medias.Payload["folders"]["create"]) =>
  match(await Medias.service.folders.create(payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ folders: D.set(data.folder.id, localizeMediaFolder(data.folder)) })
      return { error: false, id: data.folder.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateMediasFolder
 */
export const updateMediasFolder = async (
  folderId: string,
  payload: Medias.Payload["folders"]["update"]
) =>
  match(await Medias.service.folders.update(folderId, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ folders: D.set(data.folder.id, localizeMediaFolder(data.folder)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteMediasFolder
 */
export const deleteMediasFolder = async (folderId: string) =>
  match(await Medias.service.folders.delete(folderId))
    .with({ error: false }, () => {
      const ids = [
        ...getChildrenFolderIds(folderId, D.values(mediasStore.current.folders)),
        folderId,
      ]
      mediasStore.set({
        folders: D.reject(mediasStore.current.folders, ({ id }) =>
          A.includes(ids, id)
        ) as ById<MediasFolder>,
        files: D.reject(mediasStore.current.files, ({ folderId }) =>
          A.includes(ids, folderId)
        ) as ById<MediasFile>,
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createMediasFile
 */
export const createMediasFile = async (payload: Medias.Payload["files"]["create"]) =>
  match(await Medias.service.files.create(payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
      return { error: false, id: data.file.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getMediasFile
 */
export const getMediasFile = async (fileId: string) =>
  match(await Medias.service.files.read(fileId))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateMediasFile
 */
export const updateMediasFile = async (
  fileId: string,
  payload: Medias.Payload["files"]["update"]
) =>
  match(await Medias.service.files.update(fileId, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * cropMediasImage
 */
export const cropMediasImage = async (fileId: string, payload: Medias.Payload["files"]["crop"]) =>
  match(await Medias.service.files.crop(fileId, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * cropMediasImage
 */
export const copyAndCropMediasImage = async (
  fileId: string,
  payload: Medias.Payload["files"]["crop"]
) =>
  match(await Medias.service.files.copyAndCrop(fileId, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * uncropMediasImage
 */
export const uncropMediasImage = async (fileId: string) =>
  match(await Medias.service.files.uncrop(fileId))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(fileId, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteMediasFile
 */
export const deleteMediasFile = async (fileId: string) =>
  match(await Medias.service.files.delete(fileId))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.deleteKey(fileId) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * copyMediasFile
 */
export const copyMediasFile = async (fileId: string) =>
  match(await Medias.service.files.copy(fileId))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({ files: D.set(data.file.id, localizeMediaFile(data.file)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
