import { makePathToApi } from "@/fns/File"
import { ApiMediasBaseFolder, ApiMediasFile, ApiMediasTransform } from "@/services/medias/schemas"
import { parseISO } from "date-fns"
import { byLanguage } from "../languages/helpers"

/**
 * localizeMediaFolder
 */
export const localizeMediaFolder = (parsed: ApiMediasBaseFolder) => ({
  ...D.selectKeys(parsed, ["id", "parentId", "name", "name", "lock"]),
  createdAt: parseISO(parsed.createdAt),
  updatedAt: parseISO(parsed.updatedAt),
})
export type MediasFolder = ReturnType<typeof localizeMediaFolder>

/**
 * localizeMediaFile
 */
export const localizeMediaFile = (parsed: ApiMediasFile) => ({
  ...D.selectKeys(parsed, ["id", "folderId", "extension", "size", "clientName"]),
  parentId: parsed.folderId,
  url: makePathToApi(parsed.url),
  previewUrl: makePath(parsed.previewUrl),
  thumbnailUrl: makePath(parsed.thumbnailUrl),
  originalUrl: makePathToApi(parsed.originalUrl),
  transform: parsed.transform,
  translations: byLanguage(parsed.translations),
  createdAt: parseISO(parsed.createdAt),
  updatedAt: parseISO(parsed.updatedAt),
})
export type MediasFile = ReturnType<typeof localizeMediaFile>

/**
 * types
 */
export type MediasTransform = ApiMediasTransform

/**
 * helpers
 */
const makePath = (path: string) => (S.isNotEmpty(path) ? makePathToApi(path) : null)
