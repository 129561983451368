import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { PageContent } from "@/components/layout/dashboard"
import { Grid, Selection } from "@/components/collection"
import { Toolbar } from "@/components/collection/toolbar"
import { useIsAdmin } from "@/store/auth/hooks"
import { useFilteredUsers } from "@/store/users/hooks"
import { Partner, usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { filtered, matchable, filterable, sortable } = useFilteredPartners()
  const { view, setView, selected, setSelected, confirmDeleteSelection } = usePageContext()
  const isAdmin = useIsAdmin()
  return (
    <PageContent>
      {isAdmin && (
        <Selection
          selected={selected}
          unselect={() => setSelected([])}
          deleteSelection={confirmDeleteSelection}
        />
      )}
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Sort {...sortable} dictionary={""} />
        <Filters {...filterable} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Grid {...{ view }}>
        {A.map([...filtered], partner => (
          <Item key={partner.id} partner={partner} />
        ))}
      </Grid>
    </PageContent>
  )
}

/**
 * Filters
 */
type Props = ReturnType<typeof useFilteredUsers>["filterable"]
const Filters: React.FC<Props> = () => {
  return <Toolbar.Filters></Toolbar.Filters>
}

const useFilteredPartners = (initialLimit = 24, initialByMore = 12) => {
  const partners = D.values(partnersById)

  const [matchable, matchIn] = useMatchable<Partner>(["name", ({ name }) => A.join([name], " ")])

  const [sortable, sortBy] = useSortable<Partner>(
    "partners-sort",
    {
      // name: ({ name }) => name,
    },
    "name"
  )

  const [filterable, filterBy] = useFilterable<Partner>("partners-filters", {}, {})

  const filtered = React.useMemo(
    () => pipe(partners, filterBy, matchIn, sortBy),
    [partners, filterBy, matchIn, sortBy]
  )
  const [limitable, limit] = useLimitable<Partner>(filtered.length, initialLimit, initialByMore)

  return { users: partners, filtered, sortable, matchable, filterable, limitable, limit }
}

export const partnersById: ById<Partner> = {}
