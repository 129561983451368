import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { match } from "ts-pattern"
import { resetPassword } from "@/store/auth/actions"

/**
 * dictionary src/dictionaries/en/pages/reset-password.json
 */
const dictionary = createContextMapper("pages", "reset-password")

/**
 * Page: ResetPassword
 */
const ResetPassword: React.FC<{ token: string }> = ({ token }) => {
  const { _ } = useDictionary(dictionary())
  const [invalidToken, setInvalidToken] = React.useState(false)

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      password: "",
      confirm: "",
    })),
    validate: validator({
      password: [field => N.gte(S.length(S.trim(field)), 8) || _("password-length")],
      confirm: [(field, { password }) => field === password || _("password-confirm")],
    }),
    onSubmit: async ({ values: { password } }) => {
      if (!form.isValid) return _("VALIDATION_FAILURE")
      return match(await resetPassword({ password, token }))
        .with({ error: false }, () => {
          toast.success(_("toast-success"))
          navigate("/dashboard")
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _)
            .with("FETCH_ERROR", _)
            .otherwise(() => setInvalidToken(true))
        )
    },
  })

  return (
    <div className="flex justify-center items-center w-full min-h-screen p-8 bg-secondary">
      <Card className="w-full max-w-xs">
        {invalidToken ? (
          <>
            <Card.Header>
              <Card.Title>{_("title")}</Card.Title>
            </Card.Header>
            <Card.Content className="grid gap-4">
              <p className="text-sm font-light">{_("INVALID_TOKEN")}</p>
              <Button onClick={() => navigate("/forgot-password")}>{_("forgot-password")}</Button>
              <Button variant="ghost" onClick={() => navigate("/")}>
                {_("back")}
              </Button>
            </Card.Content>
          </>
        ) : (
          <>
            <Card.Header>
              <Card.Title>{_("title")}</Card.Title>
              <Card.Description>{_("secondary")}</Card.Description>
            </Card.Header>
            <Card.Content>
              <Form form={form} className="grid gap-4">
                <FormAssertive />
                <FormInput
                  label={_("password-label")}
                  name="password"
                  type="password"
                  placeholder={_("password-placeholder")}
                />
                <FormInput
                  label={_("confirm-label")}
                  name="confirm"
                  type="password"
                  placeholder={_("confirm-placeholder")}
                />
                <FormSubmit>{_("submit")}</FormSubmit>
                <Button variant="ghost" onClick={() => navigate("/")}>
                  {_("back")}
                </Button>
              </Form>
            </Card.Content>
          </>
        )}
      </Card>
    </div>
  )
}
export default ResetPassword
