import { MediasFile, MediasFolder } from "./localizers"

/**
 * getFlatChildrenIds
 */
export const getChildrenFolderIds = (currentId: string, folders: MediasFolder[]): string[] =>
  A.reduce(folders, [], (ids: string[], { id, parentId }) =>
    parentId === currentId ? [...ids, id, ...getChildrenFolderIds(id, folders)] : ids
  )

export const getChildrenMediaIds = (currentId: string, folders: MediasFolder[], files: MediasFile[]): string[] =>
  A.reduce(folders, [], (ids: string[], { id, parentId }) =>
    parentId === currentId
      ? [
          ...ids,
          id,
          ...getChildrenMediaIds(id, folders, files),
          ...A.filterMap(files, ({ id, folderId }) => (folderId === currentId ? id : O.None)),
        ]
      : ids
  )

/**
 * isMediaInFolder
 */
export const isMediaInFolder = (folderId: string | null) => (fileOrFolder: MediasFile | MediasFolder) => {
  if ("folderId" in fileOrFolder) return fileOrFolder.folderId === folderId
  return fileOrFolder.parentId === folderId
}
/**
 * isMediaInRoot
 */
export const isMediaInRoot = isMediaInFolder(null)

/**
 * getFoldersPath
 */
export const getFoldersPath = (id: string | null, byIds: ById<MediasFolder>): MediasFolder[] => {
  if (G.isNull(id)) return []
  const current = D.get(byIds, id)
  if (G.isNullable(current)) return []
  if (G.isNullable(current.parentId)) return [current]
  return [current, ...getFoldersPath(current.parentId, byIds)]
}

/**
 * isFile / isFolder
 */
export const isFile = (fileOrFolder: MediasFile | MediasFolder): fileOrFolder is MediasFile =>
  G.isNotNullable((fileOrFolder as MediasFile).extension)
export const isFolder = (fileOrFolder: MediasFile | MediasFolder): fileOrFolder is MediasFolder =>
  G.isNull((fileOrFolder as MediasFile).extension)
