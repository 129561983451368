import { FormSelectOption } from "@/components/form/select"
import { useRefreshUsers, useUsers } from "@/store/users/hooks"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "form")

export const useAuthorOptions = (): FormSelectOption[] => {
  const { _ } = useDictionary(dictionary())
  useRefreshUsers()
  const authors = useUsers()

  const authorOptions = React.useMemo(
    () => [
      { label: _("author-placeholder"), value: "null" },
      ...A.map(
        A.sortBy(authors, author => author.profile.lastname),
        author => ({
          label: `${author.profile.lastname} ${author.profile.firstname}`,
          value: author.id,
        })
      ),
    ],
    [_]
  )
  return authorOptions
}
