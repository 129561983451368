import { Address } from "@/store/types"

/**
 * Generates a Google Maps link from an address object
 */
export const addressToGoogleMapUrl = (address: Address): string => {
  const addressString = `${address.address} ${address.zip} ${address.city} ${address.state} ${address.country}`
  const cleanedAddress = addressString.replace(/[\r\n]+/g, " ")
  const sanitizedAddress = cleanedAddress.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
  const encodedAddress = encodeURIComponent(sanitizedAddress.replace(/\s+/g, "+"))
  const googleMapsLink = `https://www.google.com/maps/place/${encodedAddress}`
  return googleMapsLink
}
