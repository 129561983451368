import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * create
 */
export const create: ItemMappingExport<ItemType>["create"] = (order, languages) => ({
  type: itemType,
  order,
  props: {
    facts: [],
    titleLevel: 1,
  },
  translations: A.map(languages, (language) => ({
    languageId: language.id,
    props: { title: "", secondary: "", description: "", linkText: "", linkUrl: "", facts: {} },
  })),
  files: [],
})
