import { useTranslation } from "@/store/languages/hooks"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { tabs } = item.props

  return <article className="p-4 @lg/card:p-8 bg-white font-museo"></article>
}

/**
 * types
 */
type TabType = FormPayload<ItemType>["translations"][number]["props"]["tabs"][string]
