import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Grid } from "@/components/collection"
import { Card } from "@/components/ui/card"

/**
 * Page: Statistics
 */
const Statistics: React.FC = () => {
  //
  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[["Statistics", "/dashboard/partners"]]} />
      <PageContent>
        <Grid view="grid">
          <Card.Spotlight className="col-span-1 @xl/collection:col-span-2 @5xl/collection:col-span-3 @7xl/collection:col-span-4 min-h-96"></Card.Spotlight>
          <Card.Spotlight className="min-h-96"></Card.Spotlight>
          <Card.Spotlight className="min-h-96"></Card.Spotlight>
          <Card.Spotlight className="min-h-96"></Card.Spotlight>
          <Card.Spotlight className="min-h-96"></Card.Spotlight>
        </Grid>
      </PageContent>
    </PageWrapper>
  )
}
export default Statistics
