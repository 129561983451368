import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { usePromise } from "@/hooks/usePromise"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { useUsersStore } from "."
import { getUsers } from "./actions"
import { User } from "./localizers"
import { getFullname } from "./helpers"

export const useRefreshUsers = () => {
  return usePromise(getUsers)
}

export const useUsersById = () => {
  const byIds = useUsersStore(D.prop("users"))
  return byIds
}

export const useUser = (id: Option<string>) => {
  return useUsersStore(({ users }) => D.get(users, id ?? ""))
}

export const useUsers = () => {
  const byIds = useUsersById()
  const users = React.useMemo(() => D.values(byIds), [byIds])
  return users
}

export const useFilteredUsers = (initialLimit = 24, initialByMore = 12) => {
  const users = useUsers()

  const [matchable, matchIn] = useMatchable<User>([
    "profile.firstname",
    "profile.lastname",
    ({ profile: { firstname, lastname } }) => A.join([firstname, lastname], " "),
    // ({ createdAt }) => normString(format(createdAt, "Pp", { locale })),
    // ({ createdAt }) => normString(format(createdAt, "PPPP", { locale })),
  ])

  const [sortable, sortBy] = useSortable<User>(
    "users-sort",
    {
      firstname: ({ profile }) => profile.firstname,
      lastname: ({ profile }) => profile.lastname,
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "firstname"
  )

  const [filterable, filterBy] = useFilterable<User>(
    "users-filters",
    { "soft-deleted": ({ status }) => status === "deleted" },
    { "soft-deleted": true }
  )

  const filtered = React.useMemo(
    () => pipe(users, filterBy, matchIn, sortBy),
    [users, filterBy, matchIn, sortBy]
  )
  const [limitable, limit] = useLimitable<User>(filtered.length, initialLimit, initialByMore)

  return { users, filtered, sortable, matchable, filterable, limitable, limit }
}

/**
 * options hooks
 */
export const useAuthorOptions = (placeholder: string) => {
  const authors = useUsers()
  return React.useMemo(
    () => [
      { label: placeholder, value: "null" },
      ...A.map(
        A.sortBy(authors, author => author.profile.lastname),
        author => ({
          label: getFullname(author, true),
          value: author.id,
        })
      ),
    ],
    [authors, placeholder]
  )
}
export const useRoleOptions = (disableSuperadmin: boolean) => {
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("role-member"),
        value: "member",
      },
      {
        label: _("role-admin"),
        value: "admin",
      },
      {
        label: _("role-superadmin"),
        value: "superadmin",
        disabled: disableSuperadmin,
      },
    ],
    [disableSuperadmin, _]
  )
}
export const useStatusOptions = () => {
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("user-status-pending"),
        value: "pending",
      },
      {
        label: _("user-status-active"),
        value: "active",
      },
      {
        label: _("user-status-deleted"),
        value: "deleted",
      },
      {
        label: _("user-status-suspended"),
        value: "suspended",
      },
    ],
    [_]
  )
}
