import { Form, FormCropper, useForm } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { copyAndCropMediasImage, cropMediasImage, uncropMediasImage } from "@/store/medias/actions"
import { MediasFile } from "@/store/medias/localizers"
import { DialogClose } from "@radix-ui/react-dialog"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * CropImageDialog
 */
export const CropImageDialog: React.FC<UseDialogProps<MediasFile>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("crop-image-dialog"))
  return (
    <Dialog
      {...props}
      title={_("title")}
      description={_("secondary")}
      className="sm:max-w-xl"
      animate={false}
    >
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<MediasFile>> = ({ item: file, onOpenChange }) => {
  const { _ } = useDictionary(dictionary("crop-image-dialog"))

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      file: {
        url: file.originalUrl,
        isChange: false,
        transform: { ...initialTransform, ...file.transform },
      },
    })),
    onSubmit: async ({ values }, event) => {
      event?.stopPropagation()
      if (values.file.isChange === false) return onOpenChange(false)
      onOpenChange(false)
      const payload = { transform: values.file.transform }
      return match(await cropMediasImage(file.id, payload))
        .with({ error: false }, () => void toast.success(_("success")))
        .otherwise(() => void toast.error(_("error")))
    },
  })

  const unCrop = async () => {
    onOpenChange(false)
    match(await uncropMediasImage(file.id))
      .with({ error: false }, () => void toast.success(_("success")))
      .otherwise(() => void toast.error(_("error")))
  }

  const copyAndCrop = async () => {
    onOpenChange(false)
    const payload = { transform: form.values.file.transform }
    match(await copyAndCropMediasImage(file.id, payload))
      .with({ error: false }, () => void toast.success(_("success")))
      .otherwise(() => void toast.error(_("error")))
  }

  return (
    <Form form={form} className="grid gap-6">
      <FormCropper label={_("cropper-label")} name="file" />
      <Dialog.Footer className="sm:justify-start">
        <DialogClose asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </DialogClose>
        {D.isNotEmpty(file.transform) && (
          <Button variant="destructive" onClick={unCrop}>
            {_("undo")}
          </Button>
        )}
        <Button onClick={() => form.submit()}>{_("submit")}</Button>
        <Button onClick={copyAndCrop}>{_("copy-and-crop")}</Button>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * initialTransform
 */
const initialTransform = {
  width: 100,
  height: 100,
  x: 0,
  y: 0,
  rotate: 0,
  cropper: {
    crop: {
      x: 0,
      y: 0,
    },
    zoom: 1,
    aspect: {
      w: 0,
      h: 9,
    },
  },
}
