import { Payload as ContentPayload } from "@/services/contents/service"
import { Payload as SeoPayload } from "@/services/seos/service"
import { cmsActions as ArticlesActions } from "@/store/articles/actions"
import { Content, ContentItem } from "@/store/contents/localizers"
import { cmsActions as PagesActions } from "@/store/pages/actions"
import { Seo } from "@/store/seos/localizers"
import { Edit, Eye, EyeOff, Trash } from "lucide-react"
import { Menu } from "../collection"
import { Confirm, confirmSafeDictionary } from "../ui/confirm"
import { useConfirm } from "../ui/hooks/useConfirm"
import { useDialog } from "../ui/hooks/useDialog"
import { UpdateItemDialog } from "./dialogs/update-item"
import { UpdateSeoDialog } from "./dialogs/update-seo"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * cmsActions
 */
const cmsActions = {
  articles: ArticlesActions,
  pages: PagesActions,
}
const cmsBridgeActions = (ressource: CmsRessource) => {
  const actions = cmsActions[ressource.type]
  return {
    updateSeo: (payload: SeoPayload["update"]) => actions.updateSeo(ressource.id, payload),
    updateContent: (payload: ContentPayload["update"]) =>
      actions.updateContent(ressource.id, payload),
    createContentItem: (payload: ContentPayload["items"]["create"]) =>
      actions.createContentItem(ressource.id, payload),
    updateContentItem: (id: string, payload: ContentPayload["items"]["update"]) =>
      actions.updateContentItem(ressource.id, id, payload),
    deleteContentItem: (id: string) => actions.deleteContentItem(ressource.id, id),
    reorderContentItems: (payload: ContentPayload["items"]["reorder"]) =>
      actions.reorderContentItems(ressource.id, payload),
  }
}

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const useCmsContext = () => React.useContext(Context)
type ContextProviderProps = {
  ressource: CmsRessource
  children: React.ReactNode
}
export const CmsContextProvider: React.FC<ContextProviderProps> = ({ ressource, children }) => {
  const actions = cmsBridgeActions(ressource)

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: actions.deleteContentItem,
    dictionary: confirmSafeDictionary(dictionary("delete-item-confirm")),
  })

  // dialogs
  const { setItem: updateSeo, ...updateSeoProps } = useDialog<void>()
  const { setItem: updateItem, ...updateItemProps } = useDialog<ContentItem>()

  // quick actions
  const toggleState = (item: ContentItem) =>
    actions.updateContentItem(item.id, {
      state: item.state === "published" ? "draft" : "published",
    })

  return (
    <Context.Provider
      value={{
        ...ressource,
        actions,
        dialogs: {
          updateSeo,
          updateItem,
          confirmDelete,
          toggleState,
        },
      }}
    >
      {children}
      <UpdateSeoDialog {...updateSeoProps} />
      <Confirm {...deleteProps} />
      <UpdateItemDialog {...updateItemProps} />
    </Context.Provider>
  )
}

/**
 * SeoContextMenu
 */
type SeoContextMenuProps = {
  menu?: React.ReactNode
}
export const SeoContextMenu: React.FC<SeoContextMenuProps> = ({ menu }) => {
  const { _ } = useDictionary(dictionary("seo", "menu"))
  const {
    dialogs: { updateSeo },
  } = useCmsContext()
  return (
    <>
      <Menu.Item onClick={updateSeo}>
        <Edit aria-hidden />
        {_("edit-seo")}
      </Menu.Item>
      {menu}
    </>
  )
}

/**
 * ItemContextMenu
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
type ItemContextMenuProps = {
  menu?: React.ReactNode
  item: ContentItem
}
export const ItemContextMenu: React.FC<ItemContextMenuProps> = ({ menu, item }) => {
  const { _ } = useDictionary(dictionary("content", "menu"))
  const {
    dialogs: { confirmDelete, updateItem, toggleState },
  } = useCmsContext()
  return (
    <>
      {menu}
      <Menu.Item onClick={() => toggleState(item)}>
        {item.state === "draft" && <Eye aria-hidden />}
        {item.state === "published" && <EyeOff aria-hidden />}
        {_(item.state === "published" ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={() => updateItem(item)}>
        <Edit aria-hidden />
        {_(`edit`)}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(item.id)}>
        <Trash aria-hidden />
        {_(`delete`)}
      </Menu.Item>
    </>
  )
}

/**
 * types
 */
type CmsRessource = {
  id: string
  type: keyof typeof cmsActions
  seo: Seo
  content: Content
}
type ContextType = CmsRessource & {
  actions: ReturnType<typeof cmsBridgeActions>
  dialogs: {
    updateSeo: () => void
    updateItem: (item: ContentItem) => void
    confirmDelete: (id: string) => void
    toggleState: (item: ContentItem) => void
  }
}
