import { cva, type VariantProps } from "class-variance-authority"
import { Tooltip, TooltipProps } from "./tooltip"

/**
 * variants
 */
const badgeVariants = cva(
  "inline-flex justify-center items-center rounded-full border px-2.5 py-0.5 text-xs text-center font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

/**
 * Badge
 */
type BadgeProps = React.ComponentPropsWithoutRef<"div"> & VariantProps<typeof badgeVariants>
const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => {
    return <div ref={ref} className={cx(badgeVariants({ variant }), className)} {...props} />
  }
)

/**
 * exports
 */
export { Badge, badgeVariants, type BadgeProps }
