import { z } from "zod"
import { api, AuthErrorCode, ErrorCode, NoContent, NoError } from "../api"
import { updateProfilePayload } from "../users/service"
import { apiMe, ApiMe } from "./schemas"

/**
 * schemas
 */
const signInPayload = z.object({
  email: z.string(),
  password: z.string(),
})
const updatePayload = z.object({
  email: z.string().optional(),
  redirect: z.string().optional(),
  password: z.string().optional(),
})
const validateEmailPayload = z.object({
  token: z.string(),
})
const forgotPasswordPayload = z.object({
  email: z.string(),
  redirect: z.string(),
})
const resetPasswordPayload = z.object({
  password: z.string(),
  token: z.string(),
})
export type Payload = {
  signIn: z.infer<typeof signInPayload>
  update: z.infer<typeof updatePayload>
  validateEmail: z.infer<typeof validateEmailPayload>
  forgotPassword: z.infer<typeof forgotPasswordPayload>
  resetPassword: z.infer<typeof resetPasswordPayload>
  profile: {
    update: z.infer<typeof updateProfilePayload>
  }
}

/**
 * service
 */
export const service = {
  session: async () => {
    type RSuccess = { session: true; user: ApiMe } | { session: false }
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>("auth")
    if (!success) return { data: null, error: true, code: data.code } as const
    if (data.session)
      return { data: { session: true, user: apiMe.parse(data.user) }, error: false } as const
    return { data: { session: false }, error: false } as const
  },
  signIn: async (payload: Payload["signIn"]) => {
    type RSuccess = { user: ApiMe }
    type RError = ErrorCode<"INVALID_CREDENTIALS" | "ACCOUNT_NOT_ACTIVE" | "VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("auth/sign-in", {
      data: signInPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiMe.parse(data.user) }, error: false } as const
  },
  signOut: async () => {
    type RSuccess = NoContent
    type RError = NoError
    const { success, data } = await api.get<RSuccess, RError>("auth/sign-out")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
  update: async (payload: Payload["update"]) => {
    type RSuccess = { user: ApiMe }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.put<RSuccess, RError>("auth", {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiMe.parse(data.user) }, error: false } as const
  },
  validateEmail: async (payload: Payload["validateEmail"]) => {
    type RSuccess = { user: ApiMe }
    type RError = ErrorCode<"VALIDATION_FAILURE" | "INVALID_TOKEN" | "NO_PENDING_EMAIL">
    const { success, data } = await api.post<RSuccess, RError>("auth/validate-email", {
      data: validateEmailPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiMe.parse(data.user) }, error: false } as const
  },
  forgotPassword: async (payload: Payload["forgotPassword"]) => {
    type RSuccess = { code: "REQUEST_SENT" }
    type RError = ErrorCode<"VALIDATION_FAILURE" | "USER_NOT_FOUND" | "LIMIT_EXCEEDED">
    const { success, data } = await api.post<RSuccess, RError>("auth/forgot-password", {
      data: forgotPasswordPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: null, error: false, code: data.code } as const
  },
  resetPassword: async (payload: Payload["resetPassword"]) => {
    type RSuccess = { user: ApiMe }
    type RError = ErrorCode<"VALIDATION_FAILURE" | "INVALID_TOKEN" | "TOKEN_EXPIRED">
    const { success, data } = await api.post<RSuccess, RError>("auth/reset-password", {
      data: resetPasswordPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiMe.parse(data.user) }, error: false } as const
  },
  profile: {
    update: async (payload: Payload["profile"]["update"]) => {
      type RSuccess = { user: ApiMe }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const { image, ...rest } = updateProfilePayload.parse(payload)
      // update profile
      const { success, data } = await api.put<RSuccess, RError>("auth/profile", {
        data: { ...rest, image: image?.delete ? null : undefined },
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      // update image file
      if (G.isNotNullable(image) && G.isNotNullable(image.file)) {
        const { success, data } = await api.put<RSuccess, RError>("auth/profile", {
          form: { image: image.file },
        })
        if (!success) return { data: null, error: true, code: data.code } as const
        return { data: { user: apiMe.parse(data.user) }, error: false } as const
      }
      return { data: { user: apiMe.parse(data.user) }, error: false } as const
    },
  },
}
