import { z } from "zod"

/**
 * ApiAddress
 */
export const apiAddress = z.object({
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
})
export type ApiAddress = z.infer<typeof apiAddress>

/**
 * ApiExtraField
 */
export const apiExtraField = z.object({
  name: z.string(),
  value: z.string(),
})
export type ApiExtraField = z.infer<typeof apiExtraField>

/**
 * ApiSingleFile
 */
export const apiSingleFile = z.object({
  path: z.string(),
  url: z.string(),
})
export type ApiSingleFile = z.infer<typeof apiSingleFile>

/**
 * ApiSingleImage
 */
export const apiSingleImage = apiSingleFile.extend({
  thumbnailUrl: z.string(),
  previewUrl: z.string(),
})
export type ApiSingleImage = z.infer<typeof apiSingleImage>
