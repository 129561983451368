import { Menu, useMenu } from "@/components/collection"
import { UserAccountDialog } from "@/components/dialogs/user-account"
import { UserCreateDialog } from "@/components/dialogs/user-create"
import { UserProfileDialog } from "@/components/dialogs/user-profile"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePersistedState } from "@/hooks/usePersistedState"
import { useSelectItem } from "@/hooks/useSelect"
import { useIsAdmin } from "@/store/auth/hooks"
import { deleteUser } from "@/store/users/actions"
import { useUsersById } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import { PlusSquare, Scan, Trash, UserCog, UserIcon, UserSquare } from "lucide-react"
import { getFullname } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const usersById = useUsersById()

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "users-view",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteUser,
    dictionary: "pages.dashboard.users.delete-confirm",
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => getFullname(D.get(usersById, id), _("fullname-placeholder")),
    onAsyncConfirm: deleteUser,
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })

  // dialogs
  const { setItem: updateAccount, ...accountProps } = useDialog<User>()
  const { setItem: updateProfile, ...profileProps } = useDialog<User>()
  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        confirmDelete,
        updateAccount,
        updateProfile,
        create,
        confirmDeleteSelection,
      }}
    >
      {children}
      <Confirm {...deleteSelectionProps} />
      <Confirm {...deleteProps} />
      <UserAccountDialog {...accountProps} />
      <UserProfileDialog {...profileProps} />
      <UserCreateDialog {...createProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { user: User }
export const ContextMenu: React.FC<ContextMenuProps> = ({ user }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, confirmDeleteSelection, updateAccount, updateProfile, create } =
    usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, user.id)

  const { id } = user
  const isAdmin = useIsAdmin()
  return (
    <>
      <Menu.Item onClick={() => navigate(`/dashboard/users/${id}`)}>
        <UserIcon aria-hidden />
        {_("display")}
      </Menu.Item>
      {isAdmin && (
        <>
          <Menu.Item onClick={toggleSelection}>
            <Scan aria-hidden />
            {_(isSelected ? "unselect" : "select")}
          </Menu.Item>
          <Menu.Item onClick={() => updateAccount(user)}>
            <UserCog aria-hidden />
            {_("edit-account")}
          </Menu.Item>
          <Menu.Item onClick={() => updateProfile(user)}>
            <UserSquare aria-hidden />
            {_("edit-profile")}
          </Menu.Item>
          <Menu.Item onClick={() => confirmDelete(id)}>
            <Trash aria-hidden />
            {_("delete")}
          </Menu.Item>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {type === "context-menu" && isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  updateAccount: (value: User) => void
  updateProfile: (value: User) => void
  create: () => void
  confirmDeleteSelection: () => void
}
