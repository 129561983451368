import { PageError } from "@/components/pages/error"
import { PageLoader } from "@/components/pages/loader"
import { usePromise } from "@/hooks/usePromise"
import { validateEmail } from "@/store/auth/actions"
import { match } from "ts-pattern"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/validate-email.json
 */
const dictionary = createContextMapper("pages", "validate-email")

/**
 * Page: ValidateEmail
 */
const ValidateEmail: React.FC<{ token: string }> = ({ token }) => {
  const { _ } = useDictionary(dictionary())

  const [error, loading] = usePromise(async () =>
    match(await validateEmail({ token }))
      .with({ error: false }, () => {
        toast.success(_("toast-success"))
        return false as const
      })
      .otherwise(({ code }) =>
        match(code)
          .with("FETCH_ERROR", code => code)
          .otherwise(() => "error-message" as const)
      )
  )
  if (loading) return <PageLoader />
  else if (error === false) return <Redirect to="/dashboard" />
  return <PageError title={_("error-title")} secondary={_("error-secondary")} message={_(error)} />
}
export default ValidateEmail
