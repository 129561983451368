import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"

import { useRefreshUsers } from "@/store/users/hooks"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Page: UsersIndex
 */
const UsersIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  useRefreshUsers()
  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/users"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <UsersIndex />
  </ContextProvider>
)
