import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { useMe } from "@/store/auth/hooks"
import { createUser } from "@/store/users/actions"
import { useRoleOptions, useStatusOptions } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import { match } from "ts-pattern"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/user-create.json
 */
const dictionary = createContextMapper("components", "dialogs", "user-create")

/**
 * UserCreateDialog
 */
export const UserCreateDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      <DialogForm {...props} />
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const me = useMe()
  const isSuperadmin = me.role === "superadmin"

  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      email: "",
      password: "",
      role: "member" as User["role"],
      status: "active" as User["status"],
    })),
    validate: validator({
      email: [min(1, _form("email-required")), isEmail(_form("email-format"))],
      password: [
        min(1, _form("password-required")),
        min(8, _form("password-length", { count: 8 })),
      ],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      return match(await createUser(values))
        .with({ error: false }, ({ id }) => {
          onOpenChange(false)
          toast.success(_("success"))
          navigate(`/dashboard/users/${id}`)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  const roleOption = useRoleOptions(!isSuperadmin)
  const statusOptions = useStatusOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-connection-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-connection-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormInput
          label={_form("email-label")}
          name="email"
          type="email"
          placeholder={_form("email-placeholder")}
        />
        <FormInput
          label={_form("password-label")}
          labelAside={
            <Button
              variant="link"
              size="link"
              onClick={() => form.setValues({ password: generatePassword() })}
            >
              {_form("password-generate")}
            </Button>
          }
          name="password"
          type="password"
          placeholder={_form("password-placeholder")}
        />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-settings-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-settings-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect
          label={_form("role-label")}
          name="role"
          placeholder={_form("role-placeholder")}
          options={roleOption}
        />
        <FormSelect
          label={_form("user-status-label")}
          name="status"
          placeholder={_form("user-status-placeholder")}
          options={statusOptions}
        />
      </FormSection>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("create")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * generatePassword
 */
const generatePassword = (minLength: number = 12) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+"
  let password = ""
  for (let i = 0; i < minLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    password += characters[randomIndex]
  }
  return password
}
