import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"

import { useRefreshLanguages } from "@/store/languages/hooks"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Page: LanguagesIndex
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const LanguagesIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  useRefreshLanguages()
  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/users"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <LanguagesIndex />
  </ContextProvider>
)
