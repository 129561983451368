import { makeBreakable } from "@/fns/String"
import { Card, Menu, Row, useMenu } from "@/components/collection"
import { useMediaContext } from "@/components/medias/context"
import { useSelectItem } from "@/components/medias/useSelect"
import { Image } from "@/components/ui/image"
import { Tooltip } from "@/components/ui/tooltip"

import { MediasFolder } from "@/store/medias/localizers"
import { Folder, FolderPlus, FolderTree, PenBox, Scan, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * ItemFolder
 */
type ItemFolderProps = {
  size?: "default" | "sm" | "xs"
  folder: MediasFolder
  open?: (id: string) => void
  select?: (id: string) => void
  disabled?: boolean
  canToggleSelect?: boolean
}
export const ItemFolder: React.FC<ItemFolderProps> = ({ ...props }) => {
  const { folder, open } = props
  const { view, setCurrentFolder } = useMediaContext()
  const { selectItemProps, multiple, toggleSelection } = useSelectItem("folders", folder.id)
  const onDoubleClick = () => (open ? open(folder.id) : setCurrentFolder(folder.id))
  const onClick = (e: React.MouseEvent) => {
    if (multiple) return
    e.stopPropagation()
    toggleSelection()
  }
  return (
    <Menu type="context-menu" menu={<FolderContextMenu {...props} />}>
      <div className="grid" {...selectItemProps} onDoubleClick={onDoubleClick} onClick={onClick}>
        {view === "grid" ? <ItemCardFolder {...props} /> : <ItemRowFolder {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCardFolder
 */
export const ItemCardFolder: React.FC<ItemFolderProps> = ({ ...props }) => {
  const { size = "default", disabled } = props
  const { id, name } = props.folder
  const { isSelected } = useSelectItem("folders", id)

  return (
    <Card className="flex flex-row" selected={isSelected} size={size} disabled={disabled}>
      <Image className="w-8 h-8 rounded-full" wrapperCx={cx("pr-0", size === "xs" ? "p-2" : "p-4")}>
        <Folder aria-hidden className="h-4 text-muted-foreground" />
      </Image>
      <Card.Header
        className={cx("flex-row items-center grow pl-0", size === "xs" ? "pr-12" : "pr-16")}
        size={size}
      >
        <Card.Title
          className={cx("grow line-clamp-1", size === "xs" ? "text-xs" : "text-sm")}
          size={size}
        >
          <Tooltip content={name} side="bottom" align="start">
            <span>{makeBreakable(name, 5)}</span>
          </Tooltip>
        </Card.Title>
        <Card.Menu
          menu={<FolderContextMenu {...props} />}
          className={cx(size === "xs" ? "top-2 right-2" : "top-4 right-4")}
        />
      </Card.Header>
    </Card>
  )
}

/**
 * ItemRowFolder
 */
export const ItemRowFolder: React.FC<ItemFolderProps> = ({ ...props }) => {
  const { size = "default", disabled } = props
  const { id, name } = props.folder
  const { isSelected } = useSelectItem("folders", id)
  return (
    <Row selected={isSelected} disabled={disabled} size={size}>
      <Row.Image>
        <Folder aria-hidden className="h-4 text-muted-foreground" />
      </Row.Image>
      <Row.Header>
        <Row.Title className="text-sm line-clamp-1">
          <span>{makeBreakable(name, 5)}</span>
        </Row.Title>
      </Row.Header>
      <Row.Menu menu={<FolderContextMenu {...props} />} />
    </Row>
  )
}

/**
 * FolderContextMenu
 */
export const FolderContextMenu: React.FC<ItemFolderProps> = ({
  folder,
  open,
  select,
  canToggleSelect,
}) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { isSelected, toggleSelection } = useSelectItem("folders", folder.id)
  const { type } = useMenu()
  const isContextMenu = type === "context-menu"
  const {
    setCurrentFolder,
    currentFolder,
    confirmDeleteFolder,
    confirmDeleteSelection,
    createFolder,
    editFolder,
    moveFolder,
    moveSelection,
  } = useMediaContext()

  return (
    <>
      <Menu.Item onClick={() => (open ? open(folder.id) : setCurrentFolder(folder.id))}>
        <Folder aria-hidden />
        {_("open-folder")}
      </Menu.Item>
      {select && (
        <Menu.Item onClick={() => select(folder.id)}>
          <Scan aria-hidden />
          {_("select-folder")}
        </Menu.Item>
      )}
      {canToggleSelect && (
        <Menu.Item onClick={() => toggleSelection()}>
          <Scan aria-hidden />
          {_(isSelected ? "unselect-folder" : "select-folder")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => editFolder(folder)}>
        <PenBox aria-hidden />
        {_("edit-folder")}
      </Menu.Item>
      <Menu.Item onClick={() => moveFolder(folder.id)}>
        <FolderTree aria-hidden />
        {_("move-folder")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDeleteFolder(folder.id)}>
        <Trash aria-hidden />
        {_("delete-folder")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => createFolder(currentFolder)}>
            <FolderPlus aria-hidden />
            {_("create-folder")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={() => moveSelection()}>
                <FolderTree aria-hidden />
                {_("move-selection")}
              </Menu.Item>
              <Menu.Item onClick={() => confirmDeleteSelection()}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}
