import { FormToggleOption } from "@/components/form/toggle"
import { ChevronLeft } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "form")

export const useCornerOptions = (): FormToggleOption[] => {
  const { _ } = useDictionary(dictionary())
  const titleLevelOption = React.useMemo(
    () => [
      {
        label: <ChevronLeft aria-label={_("corner-top-left")} className="rotate-45" />,
        value: "top-left",
      },
      {
        label: <ChevronLeft aria-label={_("corner-top-right")} className="rotate-[135deg]" />,
        value: "top-right",
      },
      {
        label: <ChevronLeft aria-label={_("corner-top-left")} className="-rotate-45" />,
        value: "bottom-left",
      },
      {
        label: <ChevronLeft aria-label={_("corner-bottom-right")} className="-rotate-[135deg]" />,
        value: "bottom-right",
      },
    ],
    [_]
  )
  return titleLevelOption
}

export const toCornersProps = (corners: string[]) => ({
  tr: A.includes(corners, "top-right"),
  tl: A.includes(corners, "top-left"),
  br: A.includes(corners, "bottom-right"),
  bl: A.includes(corners, "bottom-left"),
})
