import { VariantProps, cva } from "class-variance-authority"
import { format, isDate } from "date-fns"
import { Form, FormFieldWrapper, FormFieldWrapperProps } from "."

/**
 * FormText
 */
type Props = React.ComponentProps<typeof Form.Input> &
  VariantProps<typeof inputVariants> &
  FormFieldWrapperProps & {
    type?: FormInputType
  }
export const FormInput = React.forwardRef<HTMLInputElement, Props>(
  ({ label, labelAside, name, info, type = "text", icon, size, className, ...props }, ref) => (
    <FormFieldWrapper {...{ label, labelAside, name, info }}>
      <Form.Input {...props} ref={ref} type={type} className={cx(inputVariants({ icon, size, className }))} />
    </FormFieldWrapper>
  )
)

/**
 * inputVariants
 */
export const inputVariants = cva(
  [
    "flex w-full rounded-md",
    "box-border border border-input bg-background",
    "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "placeholder:text-muted-foreground",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium",
    "disabled:cursor-not-allowed disabled:opacity-50",
  ],
  {
    variants: {
      size: {
        default: "h-10 py-2 text-sm",
        sm: "h-9 py-2 text-sm",
        xs: "h-8 py-2 text-xs",
      },
      icon: {
        default: "",
        left: "",
        right: "",
        both: "",
      },
    },
    compoundVariants: [
      { size: "default", icon: "default", class: "px-3" },
      { size: "default", icon: "left", class: "pl-10 pr-3" },
      { size: "default", icon: "right", class: "pl-3 pr-10" },
      { size: "default", icon: "both", class: "px-10" },
      { size: "sm", icon: "default", class: "px-3" },
      { size: "sm", icon: "left", class: "pl-9 pr-3" },
      { size: "sm", icon: "right", class: "pl-3 pr-9" },
      { size: "sm", icon: "both", class: "px-9" },
      { size: "xs", icon: "default", class: "px-3" },
      { size: "xs", icon: "left", class: "pl-8 pr-3" },
      { size: "xs", icon: "right", class: "pl-3 pr-8" },
      { size: "xs", icon: "both", class: "px-8" },
    ],
    defaultVariants: {
      size: "default",
      icon: "default",
    },
  }
)

/**
 * inputIconVariants
 */
export const inputIconVariants = cva(cx("absolute top-0 flex justify-center items-center"), {
  variants: {
    size: {
      default: "w-10 h-10 [&>svg]:w-4 [&>svg]:h-4",
      sm: "w-9 h-9 [&>svg]:w-4 [&>svg]:h-4",
      xs: "w-8 h-8 [&>svg]:w-3 [&>svg]:h-3",
    },
    side: {
      left: "left-0",
      right: "right-0",
    },
  },
  defaultVariants: {
    size: "default",
    side: "left",
  },
})

/**
 * helpers
 */
export const formatDateToFormInput = (date: any) => (isDate(date) ? format(date, "yyyy-LL-dd") : "")
export const formatDatetimeToFormInput = (date: any) => (isDate(date) ? format(date, "yyyy-MM-dd'T'HH:mm") : "")

/**
 * types
 */
export type FormInputType =
  | "text"
  | "email"
  | "date"
  | "datetime-local"
  | "month"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "time"
  | "week"
  | "url"
