/**
 * Skeleton
 */
type SkeletonProps = React.ComponentPropsWithoutRef<"span"> & {
  value?: string
}
export const Skeleton: React.FC<SkeletonProps> = ({ value, className, ...props }) =>
  G.isNotNullable(value) && S.isNotEmpty(S.trim(value)) ? null : (
    <span
      className={cx("inline-flex w-full h-[1em] animate-pulse rounded-md bg-muted", className)}
      aria-hidden
      {...props}
    />
  )
