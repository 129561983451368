import { ApiPage } from "@/services/pages/schemas"
import { localizeContent } from "../contents/localizers"
import { localizeSeo } from "../seos/localizers"
import { parseISO } from "date-fns"

/**
 * localize
 */
export const localizePage = ({ id, state, trackingId, seo, content, createdAt, updatedAt }: ApiPage) => ({
  id,
  state,
  trackingId,
  seo: localizeSeo(seo),
  content: localizeContent(content),
  createdAt: parseISO(createdAt),
  updatedAt: parseISO(updatedAt),
})
export type Page = ReturnType<typeof localizePage>
