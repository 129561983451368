import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSubmit,
  toSelectOptions,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { resetAllStoresAndReload } from "@/store"
import { updateLanguage } from "@/store/languages/actions"
import { Language } from "@/store/languages/localizers"
import { getByTag } from "locale-codes"
import { match } from "ts-pattern"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"
import { useLocaleOptions } from "./language-create"

/**
 * dictionary src/dictionaries/en/components/dialogs/language-edit-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "language-edit-dialog")

/**
 * LanguageEditDialog
 *
 */
export const LanguageEditDialog: React.FC<UseDialogProps<Language>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * LanguageForm
 */
const DialogForm: React.FC<UseDialogFormProps<Language>> = ({ item: language, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()

  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: language.name,
      code: language.code,
      locale: language.locale,
      status: language.status,
    },
    validate: validator({
      name: [min(1, _form("name-required"))],
      code: [min(1, _form("code-required")), field => !!getByTag(field) || _form("code-exists")],
      locale: [
        min(1, _form("locale-required")),
        field => !!getByTag(field) || _form("locale-exists"),
      ],
    }),
    onSubmit: async ({ values: { name, code, locale, status } }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      const payload = { name, code: S.toLowerCase(code), locale, status }
      return match(await updateLanguage(language.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_errors(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  const localeOptions = useLocaleOptions(form.values.code)

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_form("name-label")} name="name" placeholder={_form("name-placeholder")} />
      <FormInput label={_form("code-label")} name="code" placeholder={_form("code-placeholder")} />
      <FormSelect
        label={_form("locale-label")}
        name="locale"
        placeholder={_form("locale-placeholder")}
        options={localeOptions}
        disabled={A.isEmpty(localeOptions)}
      />
      <FormSelect
        label={_form("status-label")}
        name="status"
        placeholder={_form("status-placeholder")}
        options={toSelectOptions(["active", "deleted"], _ as (ctx: string) => string, "status")}
      />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
