import { Layers, Search } from "lucide-react"
import { ValidContextAndProps, useDictionary } from "@/dictionaries/hooks"
import { Button } from "@/components/ui/button"

/**
 * helpers
 */
type EmptyCollectionDictionary = {
  "no-item-title": string
  "no-item-before": string
  "no-item-create": string
  "no-item-after": string
  "no-result-title": string
  "no-result-before": string
  "no-result-clear": string
  "no-result-after": string
}
const EmptySafeDictionary = <T extends string>(
  context: ValidContextAndProps<T, EmptyCollectionDictionary>
) => context

/**
 * CollectionEmpty
 */
type EmptyProps = {
  listIsEmpty: boolean
  resultIsEmpty: boolean
  clearAllFilters: () => void
  create?: () => void
  dictionary: string
}
const EmptyRoot: React.FC<EmptyProps> = ({
  resultIsEmpty,
  listIsEmpty,
  clearAllFilters,
  create,
  dictionary,
}) => {
  const { _ } = useDictionary<string, false>(dictionary)

  return resultIsEmpty || listIsEmpty ? (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="flex flex-col items-center justify-center gap-2">
        {listIsEmpty ? (
          <Layers aria-hidden className="text-muted-foreground my-4" size={48} strokeWidth={1} />
        ) : (
          <Search aria-hidden className="text-muted-foreground my-4" size={48} strokeWidth={1} />
        )}
        <h2 className="text-2xl font-semibold">
          {_(listIsEmpty ? "no-item-title" : "no-result-title")}
        </h2>
        <p className="text-sm text-muted-foreground">
          {listIsEmpty ? (
            <>
              {_("no-item-before")}
              {G.isNotNullable(create) && (
                <Button variant="link" size="link" onClick={create}>
                  {_("no-item-create")}
                </Button>
              )}
              {_("no-item-after")}
            </>
          ) : (
            <>
              {_("no-result-before")}
              <Button variant="link" size="link" onClick={clearAllFilters}>
                {_("no-result-clear")}
              </Button>
              {_("no-result-after")}
            </>
          )}
        </p>
      </div>
    </div>
  ) : null
}

/**
 * exports
 */
export const Empty = Object.assign(EmptyRoot, {
  safeDictionary: EmptySafeDictionary,
})
