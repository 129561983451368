import { useTranslation } from "@/store/languages/hooks"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"
import { useMediasFile } from "@/store/medias/hooks"
import { Image } from "@/components/ui/image"
import { ImageIcon } from "lucide-react"
import { Skeleton } from "@/components/ui/skeleton"
import { HN } from "@/components/ui/hn"
import { relativeProseStyle } from "../../frontend/proseStyle"

/**
 * ItemRender
 * dictionary src/dictionaries/en/components/cms.json
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, description, linkText, linkUrl, cards } = t(item).props

  return (
    <div className="p-4 @xl/card:p-8 bg-be-cultured font-museo">
      <div className="w-full max-w-[1000px] mx-auto">
        {S.isNotEmpty(S.trim(secondary)) && (
          <p className="pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase">
            {secondary}
          </p>
        )}
        <HN
          className="w-full max-w-sm uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold text-be-midnight"
          level={titleLevel}
        >
          {title}
          <Skeleton value={title} />
        </HN>
        {S.isNotEmpty(S.trim(description)) && (
          <div
            className={cx(
              relativeProseStyle,
              "w-full max-w-lg text-sm @xl/card:text-base text-be-midgray"
            )}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <div className="flex flex-col @xl/card:grid grid-cols-2 w-full pt-[30px] gap-[25px]">
          {A.map(item.props.cards, card => (
            <Card card={D.get(cards, card) ?? emptyCard} titleLevel={titleLevel} key={card} />
          ))}
          {A.isEmpty(item.props.cards) &&
            A.map(A.range(0, 1), index => (
              <Card card={emptyCard} titleLevel={titleLevel} key={index} />
            ))}
        </div>
        {S.isNotEmpty(S.trim(linkUrl)) && (
          <div className="flex justify-center pt-[50px]">
            <span className="inline-flex items-center px-[20px] py-[10px] rounded-[25px] bg-be-orient text-be-button text-white uppercase">
              {linkText}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

type Card = FormPayload<ItemType>["translations"][number]["props"]["cards"][string]
const Card: React.FC<{ card: Card; titleLevel: number }> = ({ card, titleLevel }) => {
  const t = useTranslation()
  const image = useMediasFile(card.image)
  const { title, description, variant, linkUrl, linkText } = card

  return (
    <article className="flex flex-col shadow-be-card rounded-[5px]">
      <div className="relative w-full aspect-video rounded-t-[5px] overflow-hidden">
        <Image
          src={image?.url}
          alt={image ? t(image).alt : undefined}
          className="w-full h-full object-cover"
        >
          <ImageIcon size={64} strokeWidth={1} className="text-muted-foreground" />
        </Image>
        <div
          className={cx(
            "absolute inset-0 w-full h-full",
            variant === "primary" && "bg-be-orient/60",
            variant === "secondary" && "bg-be-milano/60"
          )}
          aria-hidden
          style={{
            mixBlendMode: "soft-light",
          }}
        />
      </div>
      <div className="grow flex flex-col items-start px-5 @xl/card:px-[30px] py-5 @xl/card:py-[40px]">
        <HN
          className="flex items-center pb-5 text-be-midnight text-[25px] @xl/card:text-[35px] leading-tight font-bold uppercase"
          level={titleLevel + 1}
        >
          {title}
          <Skeleton className="max-w-[200px]" value={title} />
        </HN>
        {S.isNotEmpty(S.trim(description)) ? (
          <div
            className={cx(relativeProseStyle, "w-full max-w-lg text-base text-be-midgray")}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          <div className="grow text-[14px] leading-tight">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton className="w-3/5" />
          </div>
        )}
        {S.isNotEmpty(S.trim(linkUrl)) && (
          <span
            className={cx(
              "inline-flex items-center justify-center w-full @xl/card:w-auto mt-[30px] px-[20px] py-[10px] rounded-[25px] bg-be-orient text-be-button text-white uppercase",
              variant === "primary" && "bg-be-orient",
              variant === "secondary" && "bg-be-milano"
            )}
          >
            {linkText}
          </span>
        )}
      </div>
    </article>
  )
}

const emptyCard = {
  image: "",
  title: "",
  description: "",
  linkText: "",
  variant: "primary" as const,
  linkUrl: "",
}
