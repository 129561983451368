import { Menu } from "@/components/collection"
import { ContextMenu, Partner } from "./Context"

/**
 * Item
 */
export const Item: React.FC<{ partner: Partner }> = ({ partner }) => {
  return (
    <Menu type="context-menu" menu={<ContextMenu partner={partner} />} asChild>
      <div className="grid">
        {/* {view === "grid" ? <ItemCard {...{ user }} /> : <ItemRow {...{ user }} />} */}
      </div>
    </Menu>
  )
}
