import { api, AuthErrorCode, NoContent } from "@/services/api"
import { z } from "zod"
import { apiAddress, apiExtraField } from "../commons/schemas"
import { ApiUser, apiUser, apiUserRole, apiUserStatus } from "./schemas"

/**
 * schemas
 */
const createPayload = z.object({
  email: z.string(),
  password: z.string().optional(),
  role: apiUserRole.optional(),
  status: apiUserStatus.optional(),
})
const updatePayload = z.object({
  email: z.string().optional(),
  password: z.string().optional(),
  role: apiUserRole.optional(),
  status: apiUserStatus.optional(),
})
export const updateProfilePayload = z.object({
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  image: z
    .object({
      file: z.any(),
      delete: z.boolean(),
    })
    .optional(),
  dob: z.string().nullable().optional(),
  position: z.string().optional(),
  company: z.string().optional(),
  phones: apiExtraField.array().optional(),
  emails: apiExtraField.array().optional(),
  address: apiAddress.optional(),
  extras: apiExtraField.array().optional(),
})
export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
  profile: {
    update: z.infer<typeof updateProfilePayload>
  }
}

export const service = {
  index: async () => {
    type RSuccess = { users: ApiUser[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("users")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { users: A.map(data.users, apiUser.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { user: ApiUser }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("users", {
      data: createPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiUser.parse(data.user) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { user: ApiUser }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`users/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiUser.parse(data.user) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { user: ApiUser }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`users/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { user: apiUser.parse(data.user) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`users/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
  profile: {
    update: async (id: string, payload: Payload["profile"]["update"]) => {
      type RSuccess = { user: ApiUser }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const { image, ...rest } = updateProfilePayload.parse(payload)
      // update profile
      const { success, data } = await api.put<RSuccess, RError>(`users/${id}/profile`, {
        data: { ...rest, image: image?.delete ? null : undefined },
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      // update image file
      if (G.isNotNullable(image) && G.isNotNullable(image.file)) {
        const { success, data } = await api.put<RSuccess, RError>(`users/${id}/profile`, {
          form: { image: image.file },
        })
        if (!success) return { data: null, error: true, code: data.code } as const
        return { data: { user: apiUser.parse(data.user) }, error: false } as const
      }
      return { data: { user: apiUser.parse(data.user) }, error: false } as const
    },
  },
}
