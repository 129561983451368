import { atc } from "tcts"

/**
 * usePromiseCallback
 */

export const usePromiseCallback = <F extends (...args: any) => any>(
  fn: F,
  provOpts: { initial?: boolean; immediate?: boolean; disableWhileActive?: boolean } = {}
): [F, boolean] => {
  const { initial, immediate, disableWhileActive } = {
    initial: provOpts.immediate && G.isNullable(provOpts.initial) ? true : false,
    immediate: false,
    disableWhileActive: false,
    ...provOpts,
  }

  const [loading, setLoading] = React.useState(initial)

  const proxy = async (...args: any[]) => {
    if (disableWhileActive && loading) return
    setLoading(true)
    try {
      const result = await fn(...args)
      setLoading(false)
      return result
    } catch {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (immediate) proxy()
  }, [immediate])

  return [proxy as F, loading]
}

/**
 * usePromise
 */

export const usePromise = <F extends (...args: any[]) => any, I = void>(
  fn: F,
  deps?: any[],
  initialResult?: I
): I extends void
  ? [Awaited<ReturnType<F>>, false] | [undefined, true]
  : [Awaited<ReturnType<F>>, false] | [I, true] => {
  const [inProgress, setInProgress] = React.useState(true)
  const [result, setResult] = React.useState(initialResult)
  const lastPromise = React.useRef<Option<symbol>>(null)

  const handlePromise = async () => {
    const currentPromise = Symbol("Request")
    lastPromise.current = currentPromise

    setInProgress(true)

    const [, result] = await atc(async () => await fn())

    if (lastPromise.current === currentPromise) {
      setResult(result)
      setInProgress(false)
    }
  }

  React.useEffect(() => {
    handlePromise()
  }, deps ?? [])

  // @ts-expect-error
  return [result, inProgress]
}

/**
 * useData
 */

export const useData = <F extends (...args: any[]) => any, I>(initial: I, fn: F, deps?: any[]) => {
  return usePromise(fn, deps, initial)
}
