import { type ItemMapping, type ContentItemMapping } from "./schemas"

/**
 * unions exports
 */
import * as headingSlider from "./headingSlider"
import * as headingVideo from "./headingVideo"
import * as articleHeading from "./articleHeading"
import * as recentsNews from "./recentsNews"
import * as actionCards from "./actionCards"
import * as factCards from "./factCards"
import * as homeCards from "./homeCards"
import * as newsletter from "./newsletter"
import * as accordionCards from "./accordionCards"
import * as heading from "./heading"
import * as accessibilityTabs from "./accessibilityTabs"
import * as richtext from "./richtext"

const Items: ItemMapping<keyof ContentItemMapping> = {
  headingSlider,
  headingVideo,
  articleHeading,
  recentsNews,
  actionCards,
  factCards,
  homeCards,
  newsletter,
  accordionCards,
  heading,
  accessibilityTabs,
  richtext,
}
export default Items
