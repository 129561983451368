import { Menu, useMenu } from "@/components/collection"
import { PageCreateDialog } from "@/components/dialogs/page-create"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePersistedState } from "@/hooks/usePersistedState"
import { useSelectItem } from "@/hooks/useSelect"
import { useTranslation } from "@/store/languages/hooks"
import { deletePage, updatePage } from "@/store/pages/actions"
import { usePagesById } from "@/store/pages/hooks"
import { Page } from "@/store/pages/localizers"
import { Eye, EyeOff, PlusSquare, Scan, Square, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Context
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const pagesById = usePagesById()
  const displayName = (id: string) => {
    const seo = D.get(pagesById, id)?.seo
    const title = seo ? t(seo).title : ""
    return S.isEmpty(S.trim(title)) ? _("no-title") : title
  }

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "pages-view",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deletePage,
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deletePage,
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmPublishSelection, ...publishSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePage(id, { state: "published" }),
    dictionary: confirmSafeDictionary(dictionary("publish-selection-confirm")),
  })
  const { confirm: confirmUnpublishSelection, ...unpublishSelectionProps } = useConfirm<
    void,
    string
  >({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePage(id, { state: "draft" }),
    dictionary: confirmSafeDictionary(dictionary("unpublish-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        confirmDelete,
        confirmDeleteSelection,
        confirmPublishSelection,
        confirmUnpublishSelection,
      }}
    >
      {children}
      <PageCreateDialog {...createProps} />
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...publishSelectionProps} />
      <Confirm {...unpublishSelectionProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { page: Page }
export const ContextMenu: React.FC<ContextMenuProps> = ({ page }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const {
    confirmPublishSelection,
    confirmUnpublishSelection,
    confirmDelete,
    confirmDeleteSelection,
    create,
  } = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, page.id)
  const { id } = page
  const isContextMenu = type === "context-menu"
  const toggleState = () =>
    updatePage(page.id, { state: page.state === "published" ? "draft" : "published" })

  return (
    <>
      <Menu.Item onClick={() => navigate(`/dashboard/pages/${page.id}`)}>
        <Square aria-hidden />
        {_("display")}
      </Menu.Item>
      <Menu.Item onClick={toggleSelection}>
        <Scan aria-hidden />
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={toggleState}>
        {page.state === "draft" && <Eye aria-hidden />}
        {page.state === "published" && <EyeOff aria-hidden />}
        {_(page.state === "published" ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmPublishSelection}>
                <Eye aria-hidden />
                {_("publish-selection")}
              </Menu.Item>
              <Menu.Item onClick={confirmUnpublishSelection}>
                <EyeOff aria-hidden />
                {_("unpublish-selection")}
              </Menu.Item>
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  create: () => void
  confirmDelete: (value: string) => void
  confirmDeleteSelection: () => void
  confirmPublishSelection: () => void
  confirmUnpublishSelection: () => void
}
