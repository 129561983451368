import { VariantProps } from "class-variance-authority"
import { FormFieldWrapper, FormFieldWrapperProps, inputVariants, useFieldContext } from "."

/**
 * FormTextarea
 */
type Props = FormFieldTextareaProps & VariantProps<typeof inputVariants> & FormFieldWrapperProps
export const FormTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ label, labelAside, name, info, icon, size, className, ...props }, ref) => (
    <FormFieldWrapper {...{ label, labelAside, name, info }}>
      <FormFieldTextarea {...props} ref={ref} className={cx(inputVariants({ icon, size, className }))} />
    </FormFieldWrapper>
  )
)

type FormFieldTextareaProps = Omit<
  React.ComponentPropsWithRef<"textarea">,
  "name" | "id" | "onChange" | "value" | "disabled" | "children"
>

/**
 * FormFieldTextarea
 */
const FormFieldTextarea = React.forwardRef<HTMLTextAreaElement, FormFieldTextareaProps>(
  ({ rows = 3, className, ...props }, ref) => {
    const { value, setFieldValue, disabled, name, id } = useFieldContext<string>()
    return (
      <textarea
        ref={ref}
        id={id}
        name={name}
        disabled={disabled}
        onChange={({ target }) => setFieldValue(target.value)}
        value={value}
        rows={rows}
        className={cx(
          className,
          "resize-none h-auto",
          "scrollbar scrollbar-w-1 scrollbar-thumb-muted scrollbar-track-background scrollbar-thumb-rounded-full"
        )}
        {...props}
      />
    )
  }
)
