import { ToolbarButton } from "./button"
import { ToolbarFilters, ToolbarFiltersButton } from "./filters"
import { ToolbarRoot } from "./root"
import { ToolbarSearch } from "./search"
import { ToolbarSort } from "./sort"
import { ToolbarView } from "./view"
export const Toolbar = Object.assign(ToolbarRoot, {
  Search: ToolbarSearch,
  Button: ToolbarButton,
  Sort: ToolbarSort,
  Filters: Object.assign(ToolbarFilters, {
    Button: ToolbarFiltersButton,
  }),
  View: ToolbarView,
})
