import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"
import { SelectProvider, useSelect } from "@/hooks/useSelect"

/**
 * Page: Partners
 */
const Partners: React.FC = () => {
  const select = useSelect(Context)
  return (
    <PageWrapper>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[["Partners", "/dashboard/partners"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <Partners />
  </ContextProvider>
)
