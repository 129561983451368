import {
  BarChartBig,
  Folders,
  GalleryVerticalEnd,
  GaugeCircle,
  GitPullRequestArrow,
  Globe,
  Layers2,
  LayoutGrid,
  LayoutList,
  Network,
  Newspaper,
  Settings2,
  Users2,
} from "lucide-react"
import { useLocation } from "wouter"
import { Accordion } from "./accordion"
import {
  SidebarDivider as Divider,
  SidebarMenu as Menu,
  SidebarButton,
  SidebarWrapper as Wrapper,
} from "./components"
import { SidebarContext } from "./context"
import { ThemeDropdown } from "../theme/dropdown"
import { useActiveLocation } from "@/components/ui/hooks/useActiveLocation"

/**
 * dictionary src/dictionaries/en/components/layout.json
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("components", "layout", "sidebar")
const dashboard = createContextMapper("pages", "dashboard")

/**
 * Sidebar
 */
export const Sidebar: React.FC = () => {
  const { _ } = useDictionary()
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const beforeClick = (): true => {
    if (isMobile) sidebar.close()
    return true
  }
  return (
    <Wrapper>
      <div className="flex flex-col w-full overflow-x-hidden">
        <div
          className="flex items-center w-full h-16 cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          <span className="flex justify-center w-16 shrink-0 text-primary">
            <LogoSquare aria-hidden className="w-6 h-6" />
          </span>
          <span
            className={cx(
              "flex flex-col text-xs leading-[14px] lea font-light uppercase whitespace-nowrap transition-opacity duration-300",
              expanded ? "opacity-1" : "opacity-0"
            )}
          >
            {pipe(
              _(dictionary("sitename")),
              S.split(" "),
              A.mapWithIndex((index, row) => <span key={index}>{row}</span>)
            )}
          </span>
        </div>
        <Divider />
      </div>
      <Menu>
        <SidebarButton
          icon={<GaugeCircle aria-hidden />}
          onClick={() => navigate("/dashboard")}
          active={isActiveLocation("/dashboard")}
        >
          {_(dashboard("index.breadcrumbs"))}
        </SidebarButton>
        <Accordion value="section-content-manager">
          <Accordion.Trigger icon={<GalleryVerticalEnd aria-hidden />}>
            {_(dictionary("menu-content-manager"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Folders aria-hidden />}
              onClick={() => navigate("/dashboard/medias")}
              active={isActivePath("/dashboard/medias")}
            >
              {_(dashboard("medias.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Layers2 aria-hidden />}
              onClick={() => navigate("/dashboard/pages")}
              active={isActivePath("/dashboard/pages")}
            >
              {_(dashboard("pages.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Newspaper aria-hidden />}
              onClick={() => navigate("/dashboard/articles")}
              active={isActivePath("/dashboard/articles", ["/dashboard/articles/categories"])}
            >
              {_(dashboard("articles.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<LayoutGrid aria-hidden />}
              onClick={() => navigate("/dashboard/articles/categories")}
              active={isActiveLocation("/dashboard/articles/categories")}
            >
              {_(dashboard("articles.categories.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        <Accordion value="section-intranet">
          <Accordion.Trigger icon={<Network aria-hidden />}>Intranet</Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<LayoutList aria-hidden />}
              onClick={() => navigate("/dashboard/partners")}
              active={isActivePath("/dashboard/partners")}
            >
              Partners
            </Accordion.Item>
            <Accordion.Item
              icon={<GitPullRequestArrow aria-hidden />}
              onClick={() => navigate("/dashboard/rfp")}
              active={isActivePath("/dashboard/rfp")}
            >
              RFP
            </Accordion.Item>
            <Accordion.Item
              icon={<BarChartBig aria-hidden />}
              onClick={() => navigate("/dashboard/statistics")}
              active={isActivePath("/dashboard/statistics")}
            >
              Statistics
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        <Accordion value="section-application">
          <Accordion.Trigger icon={<Settings2 aria-hidden />}>
            {_(dictionary("menu-application"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Users2 aria-hidden />}
              onClick={() => navigate("/dashboard/users")}
              active={isActivePath("/dashboard/users")}
            >
              {_(dashboard("users.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Globe aria-hidden />}
              onClick={() => navigate("/dashboard/languages")}
              active={isActivePath("/dashboard/languages")}
            >
              {_(dashboard("languages.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        {/* <Button icon={<LayoutTemplate />}>Button 3</Button> */}
      </Menu>
      <div className="flex flex-col w-full overflow-hidden">
        <Divider />
        <div
          className={cx(
            "flex justify-center items-center py-4 gap-4",
            expanded ? "px-4" : "flex-col"
          )}
        >
          <ThemeDropdown beforeClick={beforeClick} />
          {/* <UserDropdown expanded={expanded} /> */}
        </div>
      </div>
    </Wrapper>
  )
}

const LogoSquare = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5362 3.91306L4.89734 17.4525V30H17.5352L31.1731 16.4606V3.91306H18.5362Z"
      // fill="#66C2FF"
      fill="#47b5ff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6369 0L0 13.5394V26.087H12.6379L26.2748 12.5475V0H13.6369Z"
      // fill="#E30613"
      fill="#df0613"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.89734 26.087H12.6379L26.2748 12.5475V3.91205H18.5352L4.89834 17.4535L4.89734 26.087Z"
      // fill="#462882"
      fill="#40217e"
    />
  </svg>
)
