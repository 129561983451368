import { z } from "zod"
import { ApiLanguage, apiLanguage } from "./schemas"
import { AuthErrorCode, NoContent, api } from "../api"

/**
 * schemas
 */
const createPayload = z.object({
  name: z.string(),
  code: z.string(),
  locale: z.string(),
})
const updatePayload = z.object({
  name: z.string().optional(),
  code: z.string().optional(),
  locale: z.string().optional(),
})
export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { languages: ApiLanguage[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("languages")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { languages: A.map(data.languages, apiLanguage.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { language: ApiLanguage }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("languages", { data: createPayload.parse(payload) })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { language: apiLanguage.parse(data.language) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { language: ApiLanguage }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`languages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { language: apiLanguage.parse(data.language) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { language: ApiLanguage }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`languages/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { language: apiLanguage.parse(data.language) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`languages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
}
