/**
 * Definition: Decorate component
 */

// constants
const cornerSize = 86
const diamondSize = 176
const diamondOffset = 17

// props
type DecoratableProps = {
  children: React.ReactElement // please not node
  tr?: boolean
  tl?: boolean
  bl?: boolean
  br?: boolean
  size?: number
}

/**
 * Decorated:
 * decorated wrapper
 */

export const Decorate: React.FC<
  DecoratableProps & { color?: string; offset?: number; includeOffset?: boolean }
> = props => {
  const size = props.size ?? diamondSize
  const offset = props.offset ?? diamondOffset
  const offsetPX = `-${offset}px`

  const styles = { width: size, color: props.color }
  const matchTheme = !props.color
  const diamondClasses = cx(matchTheme && "text-secondary dark:text-primary")

  return (
    <div className="relative" style={props.includeOffset ? { margin: offset } : {}}>
      <span className={diamondClasses} aria-hidden>
        {props.tl && (
          <DiamondSVG
            className="aspect-square absolute rotate-90"
            style={{ ...styles, top: offsetPX, left: offsetPX }}
          />
        )}

        {props.tr && (
          <DiamondSVG
            className="aspect-square absolute"
            style={{ ...styles, top: offsetPX, right: offsetPX }}
          />
        )}

        {props.br && (
          <DiamondSVG
            className="aspect-square absolute rotate-90"
            style={{ ...styles, bottom: offsetPX, right: offsetPX }}
          />
        )}

        {props.bl && (
          <DiamondSVG
            className="aspect-square absolute"
            style={{ ...styles, bottom: offsetPX, left: offsetPX }}
          />
        )}
      </span>

      <div className="relative">{React.cloneElement(props.children, { _size: size * 0.5 })}</div>

      <span className={diamondClasses} aria-hidden>
        {props.tl && (
          <DiamondOverlaySVG
            className="aspect-square absolute -rotate-90"
            style={{ ...styles, top: offsetPX, left: offsetPX }}
          />
        )}

        {props.tr && (
          <DiamondOverlaySVG
            className="aspect-square absolute"
            style={{ ...styles, top: offsetPX, right: offsetPX }}
          />
        )}

        {props.br && (
          <DiamondOverlaySVG
            className="aspect-square absolute rotate-90"
            style={{ ...styles, bottom: offsetPX, right: offsetPX }}
          />
        )}

        {props.bl && (
          <DiamondOverlaySVG
            className="aspect-square absolute rotate-180"
            style={{ ...styles, bottom: offsetPX, left: offsetPX }}
          />
        )}
      </span>
    </div>
  )
}

/**
 * Clip:
 * clip wrapper
 */

export const Clip: React.FC<DecoratableProps & { _size?: number }> = props => {
  const size = props.size ?? props._size ?? cornerSize
  const sizePX = `${size}px`

  return (
    <div
      className="overflow-hidden rounded-md"
      style={{
        clipPath: `polygon(0 ${sizePX}, ${
          props.tl ? "" : "0 0,"
        } ${sizePX} 0, calc(100% - ${sizePX}) 0, ${
          props.tr ? "" : "100% 0,"
        }100% ${sizePX}, 100% calc(100% - ${sizePX}),${
          props.br ? "" : "100% 100%,"
        }calc(100% - ${sizePX}) 100%, ${sizePX} 100%, ${
          props.bl ? "" : "0 100%,"
        }0 calc(100% - ${sizePX})`,
      }}
    >
      {props.children}
    </div>
  )
}

/**
 * svgs
 */
const DiamondSVG: React.FC<{ className?: string; style?: React.CSSProperties }> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 161 161" {...props}>
    <path
      stroke="currentColor"
      strokeWidth="3"
      d="M158.475 83.157 77.834 2.525A3.5 3.5 0 0 0 75.359 1.5H5A3.5 3.5 0 0 0 1.5 5v70.368a3.5 3.5 0 0 0 1.025 2.475l80.635 80.632a3.501 3.501 0 0 0 2.475 1.025H156a3.5 3.5 0 0 0 3.5-3.5V85.632c0-.928-.369-1.819-1.025-2.475Z"
    />
  </svg>
)
const DiamondOverlaySVG: React.FC<{ className?: string; style?: React.CSSProperties }> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 161 161" {...props}>
    <path stroke="currentColor" strokeLinecap="round" strokeWidth="3" d="M80 4.7 155.3 80" />
  </svg>
)
