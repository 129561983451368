import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { Menu } from "@/components/collection"
import { SelectFiles } from "@/components/medias/components/select-files"
import { FileInfoDialog } from "@/components/medias/dialogs/file-info"
import { Button, buttonFocus } from "@/components/ui/button"
import { Image } from "@/components/ui/image"
import { SrOnly } from "@/components/ui/sr-only"

import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import delay from "delay"
import { ImageDown, ImageMinus, ImagePlus, Info, MoreVertical } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * FormMediasImages
 */
type FormMediasImagesProps = { contextKey?: string }
export const FormMediasImages: React.FC<FormMediasImagesProps & FormFieldWrapperProps> = ({
  contextKey,
  ...wrapperProps
}) => (
  <FormFieldWrapper {...wrapperProps}>
    <FormMediasImagesInput {...{ contextKey }} />
  </FormFieldWrapper>
)

/**
 * FormMediasImagesInput
 * dictionary src/dictionaries/en/components/medias.json
 */
const FormMediasImagesInput: React.FC<FormMediasImagesProps> = ({ contextKey }) => {
  const { _ } = useDictionary(dictionary())
  const { setFieldValue, value, id } = useFieldContext<string[]>()

  // add images
  const [open, onOpen] = React.useState(false)
  const onSelect = (files: string[]) => {
    // check if file is already in the list
    setFieldValue([...value, ...A.difference(files, value)])
  }

  // remove an image
  const onRemove = (fileId: string) => {
    setFieldValue(A.reject(value, id => id === fileId))
    delay(10).then(() => focusRef.current?.focus())
  }

  // replace an image by another
  const [replaceFileOpen, setReplaceFileOpen] = React.useState(false)
  const [replaceId, setReplaceId] = React.useState<string | null>(null)
  const onChange = (fileId: string) => {
    setReplaceId(fileId)
    setReplaceFileOpen(true)
  }
  const replaceBy = (files: string[]) => {
    setReplaceFileOpen(false)
    const originalId = replaceId
    setReplaceId(null)
    const fileId = A.head(files)
    if (G.isNullable(fileId)) return
    setFieldValue(A.map(value, id => (id === originalId ? fileId : id)))
    setFocusId(fileId)
  }

  // drag and drop reordering
  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        const oldIndex = value.indexOf(active.id as string)
        const newIndex = value.indexOf(over!.id as string)
        setFieldValue(arrayMove(value, oldIndex, newIndex))
      }
    },
    [value, setFieldValue]
  )
  // keyboard accessibility reordering
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => {
      const keyCode = e.key
      if (!A.includes(["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], keyCode)) return
      e.preventDefault()
      const index = value.indexOf(id)
      switch (keyCode) {
        case "ArrowUp": {
          const newIndex = index - 3
          if (newIndex < 0) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowDown": {
          const newIndex = index + 3
          if (newIndex >= value.length) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowLeft": {
          const newIndex = index - 1
          if (newIndex < 0) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
        case "ArrowRight": {
          const newIndex = index + 1
          if (newIndex >= value.length) return
          setFieldValue(arrayMove(value, index, newIndex))
          break
        }
      }
    },
    [value, setFieldValue]
  )

  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  const focusRef = React.useRef<HTMLButtonElement>(null)
  const [focusId, setFocusId] = React.useState<string | null>(null)

  return (
    <div>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={value} strategy={rectSortingStrategy}>
          <ul className="grid grid-cols-3 gap-2">
            {A.map(value, id => (
              <FormMediasImagesFile
                key={id}
                {...{ id, onChange, onRemove, onKeyDown, focusId, setFocusId }}
              />
            ))}
            <li className="h-32">
              <button
                ref={focusRef}
                className="flex justify-center items-center w-full aspect-square h-32 border border-input rounded-md bg-muted text-muted-foreground  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                id={id}
                type="button"
                onClick={() => onOpen(true)}
              >
                <ImagePlus size={48} strokeWidth={0.6} aria-hidden />
                <SrOnly>{_("add-image")}</SrOnly>
              </button>
            </li>
          </ul>
        </SortableContext>
      </DndContext>
      <SelectFiles
        open={open}
        type="image"
        onOpenChange={onOpen}
        onCloseAutoFocus={() => focusRef.current?.focus()}
        onSelect={onSelect}
        contextKey={contextKey}
        hiddenFiles={value}
        multiple
      />
      <SelectFiles
        open={replaceFileOpen}
        type="image"
        onOpenChange={setReplaceFileOpen}
        onCloseAutoFocus={() => setFocusId(replaceId)}
        onSelect={replaceBy}
        contextKey={contextKey}
        hiddenFiles={value}
      />
    </div>
  )
}

/**
 * FormMediasImagesFile
 * dictionary src/dictionaries/en/components/medias.json
 */
type FormMediasImagesFileProps = {
  id: string
  focusId: string | null
  setFocusId: (fileId: string | null) => void
  onChange: (fileId: string) => void
  onRemove: (fileId: string) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => void
}
const FormMediasImagesFile: React.FC<FormMediasImagesFileProps> = ({
  id,
  focusId,
  setFocusId,
  onChange,
  onRemove,
  onKeyDown,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })
  const style = { transform: CSS.Transform.toString(transform), transition }

  const file = useMediasFile(id)
  const [openInfo, onOpenInfoChange] = React.useState(false)

  const focusRef = React.useRef<HTMLButtonElement>(null)
  React.useEffect(() => {
    if (focusId === id) {
      focusRef.current?.focus()
      setFocusId(null)
    }
  }, [focusId, id, setFocusId])

  if (G.isNullable(file)) {
    onRemove(id)
    return null
  }

  return (
    <li
      ref={setNodeRef}
      style={style}
      className={cx(
        "relative flex justify-center items-center h-32 rounded-md border border-input transition-opacity",
        isDragging ? "opacity-75 z-20" : "opacity-100  z-10"
      )}
    >
      <Image
        src={file.url}
        alt={t(file).name}
        wrapperCx="w-full h-full rounded-md"
        className={cx("w-full h-full rounded-md object-cover")}
      />

      <button
        className={cx("absolute inset-0 w-full h-full rounded-md", buttonFocus)}
        {...listeners}
        {...attributes}
        type="button"
        onKeyDown={e => onKeyDown(e, id)}
      >
        <SrOnly>{_("drag-image")}</SrOnly>
      </button>

      <Menu
        menu={
          <>
            <Menu.Item onClick={() => onOpenInfoChange(true)}>
              <Info aria-hidden />
              {_("file-info")}
            </Menu.Item>
            <Menu.Item onClick={() => onRemove(file.id)}>
              <ImageMinus aria-hidden />
              {_("remove-image")}
            </Menu.Item>
            <Menu.Item onClick={() => onChange(file.id)}>
              <ImageDown aria-hidden />
              {_("change-image")}
            </Menu.Item>
          </>
        }
        type="dropdown-menu"
        align="start"
        side="left"
      >
        <Button
          ref={focusRef}
          variant="transparent"
          size="xxs"
          icon
          className="absolute top-2 right-2"
        >
          <MoreVertical aria-hidden />
          <SrOnly>{_("more")}</SrOnly>
        </Button>
      </Menu>
      <FileInfoDialog
        {...{ file, openInfo, setOpenInfo: onOpenInfoChange }}
        onCloseAutoFocus={() => focusRef.current?.focus()}
      />
    </li>
  )
}
