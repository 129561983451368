import { Image } from "@/components/ui/image"
import { Skeleton } from "@/components/ui/skeleton"
import { HN } from "@/components/ui/hn"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFiles } from "@/store/medias/hooks"
import { ImageIcon } from "lucide-react"
import { Clip, Decorate } from "../../frontend/Decorate"
import { relativeProseStyle } from "../../frontend/proseStyle"
import { toCornersProps } from "../../hooks/useCornerOptions"
import { ContentItemMapping, ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { imageSide, fixeHeight, scheme, displayNext } = item.props
  return (
    <article
      className={cx(
        "px-4 @lg/card:px-8 py-8 bg-white rounded-b-md font-museo",
        fixeHeight && "@xl/card:h-[832px]",
        scheme === "light" ? "bg-white" : "bg-be-midnight"
      )}
    >
      <div
        className={cx(
          "flex flex-col @xl/card:grid grid-cols-2 @xl/card:items-center w-full max-w-[1000px] mx-auto gap-8",
          fixeHeight && "h-full",
          displayNext && "relative"
        )}
      >
        {imageSide === "left" ? (
          <>
            <SideSlider item={item} />
            <SideText item={item} />
          </>
        ) : (
          <>
            <SideText item={item} />
            <SideSlider item={item} />
          </>
        )}
        {displayNext && (
          <span
            className={cx(
              "absolute left-0 hidden @xl/card:inline-flex justify-center items-center w-10 h-[64px] rounded-full",
              fixeHeight ? "bottom-8" : "bottom-0",
              scheme === "light"
                ? "border border-be-alabaster text-be-midgray"
                : "bg-white/20 backdrop-blur-md text-white"
            )}
          >
            <ArrowBottom className="text-[#5C677DC]" />
          </span>
        )}
      </div>
    </article>
  )
}

const SideText: React.FC<{ item: ContentItemMapping[ItemType] }> = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, scheme } = item.props
  const { title, secondary, description, linkText, linkUrl } = t(item).props
  return (
    <div className="flex flex-col justify-center items-start">
      {S.isNotEmpty(S.trim(secondary)) && (
        <p className="pb-2.5 @xl/card:pb-5 text-xs @xl/card:text-base leading-normal font-semibold text-be-manatee uppercase">
          {secondary}
        </p>
      )}
      <HN
        className={cx(
          "w-full max-w-sm uppercase text-[25px] @xl/card:text-[40px] leading-tight font-bold",
          scheme === "light" ? "text-be-midnight" : "text-white"
        )}
        level={titleLevel}
      >
        {title}
        <Skeleton value={title} />
        <Skeleton className="w-3/4" value={title} />
      </HN>
      {S.isNotEmpty(S.trim(description)) && (
        <div
          className={cx(
            relativeProseStyle,
            "w-full max-w-lg text-sm @xl/card:text-base",
            scheme === "light" ? "text-be-midgray" : "text-be-alabaster"
          )}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {S.isNotEmpty(S.trim(linkUrl)) && (
        <a
          href={linkUrl}
          className="inline-flex items-center mt-[10px] px-5 py-2.5 rounded-[25px] bg-be-orient text-be-button text-white uppercase"
        >
          {linkText}
        </a>
      )}
    </div>
  )
}

const SideSlider: React.FC<{ item: ContentItemMapping[ItemType] }> = ({ item }) => {
  const t = useTranslation()
  const { imageSide } = item.props
  const [page, setPage] = React.useState(1)
  const images = useMediasFiles(item.props.images)
  const image = A.get(images, page - 1)

  return (
    <div
      className={cx(
        "flex justify-center items-center",
        imageSide === "left" ? "@xl/card:justify-start" : "@xl/card:justify-end"
      )}
    >
      <div className="flex flex-col">
        <Decorate {...toCornersProps(item.props.cornerDecoration)} color="#66C2FF">
          <Clip {...toCornersProps(item.props.cornerClip)}>
            {G.isNotNullable(image) ? (
              <Image
                className="w-auto max-w-full h-auto max-h-[400px] rounded-[5px]"
                src={image.previewUrl}
                alt={t(image).alt}
              >
                <div
                  className="flex justify-center items-center w-[300px] aspect-[3/4] bg-be-alabaster text-be-midgray"
                  aria-hidden
                >
                  <ImageIcon size={64} strokeWidth={1} />
                </div>
              </Image>
            ) : (
              <Skeleton className="w-72 aspect-[3/4]" />
            )}
          </Clip>
        </Decorate>
        <div className="relative flex py-2.5 gap-2.5">
          <button
            type="button"
            className="disabled:text-be-manatee text-be-orient"
            disabled={page <= 1}
            onClick={() => page > 1 && setPage(page - 1)}
          >
            <ArrowLeft aria-hidden />
          </button>
          <span className="text-be-orient">
            {page} <span className="text-be-manatee">/ {images.length}</span>
          </span>
          <button
            type="button"
            className="disabled:text-be-manatee text-be-orient"
            disabled={page >= images.length}
            onClick={() => page < images.length && setPage(page + 1)}
          >
            <ArrowRight aria-hidden />
          </button>
        </div>
      </div>
    </div>
  )
}

/**
 * icons
 */
const ArrowBottom = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="CurrentColor"
      strokeLinecap="square"
      strokeWidth={1.5}
      d="M10 3.125v13.75m0 0L4.375 11.25M10 16.875l5.625-5.625"
    />
  </svg>
)
const ArrowLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="CurrentColor"
      strokeLinecap="square"
      strokeWidth={1.5}
      d="M16.875 10H3.125m0 0L8.75 4.375M3.125 10l5.625 5.625"
    />
  </svg>
)
const ArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="CurrentColor"
      strokeLinecap="square"
      strokeWidth={1.5}
      d="M3.125 10h13.75m0 0L11.25 4.375M16.875 10l-5.625 5.625"
    />
  </svg>
)
