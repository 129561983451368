import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateSession } from "@/store/auth/actions"
import { useMe } from "@/store/auth/hooks"
import { updateUser } from "@/store/users/actions"
import { useRoleOptions, useStatusOptions } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import { match } from "ts-pattern"
import { confirmAlert } from "../ui/confirm"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/user-account.json
 */
const dictionary = createContextMapper("components", "dialogs", "user-account")

/**
 * UserAccountDialog
 */
export const UserAccountDialog: React.FC<UseDialogProps<User>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<User>> = ({ item: user, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const me = useMe()
  const isMe = me.id === user.id
  const isSuperadmin = me.role === "superadmin"

  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      email: user?.email ?? "",
      role: user.role,
      status: user.status,
      password: "",
    })),
    validate: validator({
      email: [min(1, _form("email-required")), isEmail(_form("email-format"))],
      password: [
        field =>
          S.isEmpty(S.trim(field)) ||
          N.gte(S.length(S.trim(field)), 8) ||
          _form("password-length", { count: 8 }),
      ],
    }),
    onSubmit: async ({ values: { email, role, status, password } }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      const data = {
        email,
        role,
        status,
        password: S.isEmpty(S.trim(password)) ? undefined : S.trim(password),
      }

      // alert if user is trying to change his own role
      if (isMe && data.role !== me.role)
        if (!(await confirmAlert({ dictionary: dictionary("confirm-change-role") }))) return

      // alert if user is trying to change his own status
      if (isMe && data.status !== me.status)
        if (!(await confirmAlert({ dictionary: dictionary("confirm-change-status") }))) return

      return match(await updateUser(user.id, data))
        .with({ error: false }, () => {
          onOpenChange(false)
          toast.success(_("success"))
          if (isMe) updateSession()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(() => void toast.error(_errors("FETCH_ERROR")))
        )
    },
  })

  const disabled = user.role === "superadmin" && !isSuperadmin
  const roleOptions = useRoleOptions(!isSuperadmin)
  const statusOptions = useStatusOptions()

  return (
    <Form form={form} className="grid gap-6">
      {disabled && (
        <div className="text-sm text-foreground/75 font-light">{_("superadmin-message")}</div>
      )}
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-connection-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-connection-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormInput
          label={_form("email-label")}
          name="email"
          type="email"
          placeholder={_form("email-placeholder")}
          disabled={disabled}
        />
        <FormInput
          label={_form("password-label")}
          name="password"
          type="password"
          placeholder={_form("password-placeholder")}
          disabled={disabled}
        />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-settings-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-settings-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect
          label={_form("role-label")}
          name="role"
          placeholder={_form("role-placeholder")}
          disabled={disabled}
          options={roleOptions}
        />
        <FormSelect
          label={_form("user-status-label")}
          name="status"
          placeholder={_form("user-status-placeholder")}
          disabled={disabled}
          options={statusOptions}
        />
      </FormSection>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
