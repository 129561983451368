import { useDateFnsLocale } from "@/dictionaries/hooks"
import { normString } from "@/fns/search"
import { useLimitable } from "@/hooks/useLimitable"
import { usePromise } from "@/hooks/usePromise"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { format } from "date-fns"
import { useArticlesStore } from "."
import { useTranslation } from "../languages/hooks"
import { getArticleCategories, getArticles } from "./actions"
import { Article, ArticleCategory } from "./localizers"

export const useRefreshArticleCategories = () => {
  return usePromise(getArticleCategories)
}
export const useRefreshArticles = () => {
  return usePromise(getArticles)
}
export const useArticlesById = () => {
  const byIds = useArticlesStore(D.prop("articles"))
  return byIds
}
export const useArticleCategoriesById = () => {
  const byIds = useArticlesStore(D.prop("categories"))
  return byIds
}
export const useArticles = () => {
  const byIds = useArticlesById()
  const articles = React.useMemo(() => D.values(byIds), [byIds])
  return articles
}
export const useArticleCategories = () => {
  const byIds = useArticleCategoriesById()
  const categories = React.useMemo(() => D.values(byIds), [byIds])
  return categories
}
export const useArticle = (id: Option<string>) => {
  const article = useArticlesStore(flow(D.prop("articles"), D.get(id ?? "")))
  return article
}
export const useArticleCategory = (id: Option<string>) => {
  const page = useArticlesStore(flow(D.prop("categories"), D.get(id ?? "")))
  return page
}

export const useFilteredArticles = (initialLimit = 24, initialByMore = 12) => {
  const articles = useArticles()
  const categoriesById = useArticleCategoriesById()
  const locale = useDateFnsLocale()
  const t = useTranslation()

  const [matchable, matchIn] = useMatchable<Article>([
    ({ seo }) => t(seo).title,
    ({ seo }) => t(seo).description,
    ({ category }) =>
      G.isNotNullable(D.get(categoriesById, category ?? ""))
        ? t(D.getUnsafe(categoriesById, category ?? "")).name
        : "",
    ({ createdAt }) => normString(format(createdAt, "Pp", { locale })),
    ({ createdAt }) => normString(format(createdAt, "PPPP", { locale })),
  ])

  const [sortable, sortBy] = useSortable<Article>(
    "articles-sort",
    {
      title: ({ seo }) => t(seo).title,
      category: ({ category }) =>
        G.isNotNullable(D.get(categoriesById, category ?? ""))
          ? t(D.getUnsafe(categoriesById, category ?? "")).name
          : "",
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "title"
  )
  const filtered = React.useMemo(() => pipe(articles, matchIn, sortBy), [articles, matchIn, sortBy])
  const [limitable, limit] = useLimitable<Article>(filtered.length, initialLimit, initialByMore)

  return { articles, filtered, matchable, sortable, sortBy, limitable, limit }
}

export const useFilteredArticleCategories = () => {
  const categories = useArticleCategories()
  const t = useTranslation()

  const [matchable, matchIn] = useMatchable<ArticleCategory>([category => t(category).name])

  const [sortable, sortBy] = useSortable<ArticleCategory>(
    "article-categories-sort",
    {
      name: category => t(category).name,
    },
    "name"
  )
  const filtered = React.useMemo(
    () => pipe(categories, matchIn, sortBy),
    [categories, matchIn, sortBy]
  )

  return { categories, filtered, matchable, sortable, sortBy }
}

/**
 * options hooks
 */
export const useCategoryOptions = (placeholder: string) => {
  const categories = useArticleCategories()
  const t = useTranslation()
  return React.useMemo(
    () => [
      { label: placeholder, value: "null" },
      ...A.map(
        A.sortBy(categories, category => t(category).name),
        category => ({
          label: t(category).name,
          value: category.id,
        })
      ),
    ],
    [categories, placeholder, t]
  )
}

export const useStateOptions = () => {
  // dictionary src/dictionaries/en/pages/dashboard/articles.json
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("state-draft"),
        value: "draft",
      },
      {
        label: _("state-published"),
        value: "published",
      },
    ],
    [_]
  )
}

export const useTypeOptions = () => {
  // dictionary src/dictionaries/en/pages/dashboard/articles.json
  const { _ } = useDictionary(contextMapper("pages", "dashboard", "articles"))
  return React.useMemo(
    () => [
      { label: _("type-news"), value: "news" },
      { label: _("type-case-studies"), value: "case-studies" },
    ],
    [_]
  )
}
