import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * create
 */
export const create: ItemMappingExport<ItemType>["create"] = (order, languages) => ({
  type: itemType,
  order,
  props: {
    titleLevel: 1,
    author: null,
  },
  translations: A.map(languages, (language) => ({
    languageId: language.id,
    props: {
      title: "",
      secondary: "",
      linkText: "",
      linkUrl: "",
      image: null,
    },
  })),
  files: [],
})
