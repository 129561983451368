import { Card, Menu, Row, useMenu } from "@/components/collection"
import { useMediaContext } from "@/components/medias/context"
import { useSelectItem } from "@/components/medias/useSelect"
import { Badge } from "@/components/ui/badge"
import { Image } from "@/components/ui/image"
import { Tooltip } from "@/components/ui/tooltip"
import { makeBreakable, truncateText } from "@/fns/String"
import { humanFileSize } from "@/fns/human"
import { useDropZone } from "@/hooks/useDropZone"
import { useTranslation } from "@/store/languages/hooks"
import { copyMediasFile } from "@/store/medias/actions"
import { MediasFile } from "@/store/medias/localizers"
import { saveAs } from "file-saver"
import {
  CopyIcon,
  Download,
  FileImage,
  Files,
  Film,
  FolderPlus,
  FolderTree,
  Info,
  Monitor,
  PenBox,
  Scaling,
  Scan,
  Trash,
} from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * ItemFile
 */
type ItemFileProps = {
  file: MediasFile
  size?: "xs" | "default"
  open?: (fileId: string) => void
  select?: (fileId: string) => void
  canToggleSelect?: boolean
}
export const ItemFile: React.FC<ItemFileProps> = props => {
  const { file } = props
  const { view } = useMediaContext()
  const { selectItemProps, multiple, toggleSelection } = useSelectItem(
    "files",
    file.id,
    file.extension
  )
  const onClick = (e: React.MouseEvent) => {
    if (multiple) return // selecto manage selection
    // if (G.isNotNullable(accepted) && !accepted.includes(file.extension)) return // unselectable file (unaccepted must be hidden)
    e.stopPropagation()
    toggleSelection()
  }
  const onDoubleClick = () => {
    if (props.open) return props.open(file.id)
    if (props.select) return props.select(file.id)
  }
  return (
    <Menu type="context-menu" menu={<FileContextMenu {...props} />}>
      <div
        className="grid h-full"
        {...selectItemProps}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
      >
        {view === "grid" ? <ItemCardFile {...props} /> : <ItemRowFile {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCardFile
 */
export const ItemCardFile: React.FC<ItemFileProps> = props => {
  const { file, size = "default" } = props
  const t = useTranslation()
  const { isSelected } = useSelectItem("files", file.id)
  const { name } = t(file)
  return (
    <Card className={cx("flex flex-row")} selected={isSelected} size={size}>
      <ImagePreview src={file.thumbnailUrl}>
        <Image
          className={cx("w-8 h-8 rounded-full")}
          wrapperCx={cx("pr-0", size === "xs" ? "p-2" : "p-4")}
          src={file.thumbnailUrl}
        >
          <FileIcon {...props} />
        </Image>
      </ImagePreview>
      <Card.Header
        className={cx("flex-row items-center grow pl-0", size === "xs" ? "pr-12" : "pr-16")}
        size={size}
      >
        <Card.Title
          className={cx("grow line-clamp-1", size === "xs" ? "text-xs" : "text-sm")}
          size={size}
        >
          <Tooltip content={name} side="bottom" align="start">
            <span>
              {makeBreakable(name, 5)}
              {S.isNotEmpty(file.extension) ? `.${file.extension}` : ""}
            </span>
          </Tooltip>
        </Card.Title>
        <Card.Menu
          menu={<FileContextMenu {...props} />}
          className={cx(size === "xs" ? "top-2 right-2" : "top-4 right-4")}
        />
      </Card.Header>
    </Card>
  )
}

/**
 * ItemRowFile
 */
export const ItemRowFile: React.FC<ItemFileProps> = props => {
  const { file, size = "default" } = props
  const t = useTranslation()
  const { isSelected } = useSelectItem("files", file.id)
  const { name } = t(file)
  const fullName = S.isNotEmpty(file.extension) ? `${name}.${file.extension}` : name
  return (
    <Row selected={isSelected} size={size}>
      <ImagePreview src={file.thumbnailUrl}>
        <Row.Image src={file.thumbnailUrl}>
          <FileIcon {...props} />
        </Row.Image>
      </ImagePreview>
      <Row.Header>
        <Row.Title className="text-sm line-clamp-1">{makeBreakable(fullName)}</Row.Title>
      </Row.Header>
      <Row.Content className="flex gap-4">
        <Badge variant="secondary" className="w-24">
          {humanFileSize(file.size)}
        </Badge>
      </Row.Content>
      <Row.Menu menu={<FileContextMenu {...props} />} />
    </Row>
  )
}

/**
 * FileContextMenu
 */
export const FileContextMenu: React.FC<ItemFileProps> = ({
  file,
  open,
  select,
  canToggleSelect = false,
}) => {
  const { _ } = useDictionary(dictionary("menu"))
  const t = useTranslation()
  const { isSelected, toggleSelection } = useSelectItem("files", file.id)
  const { type } = useMenu()
  const isImage = A.includes(["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"], file.extension)
  const isContextMenu = type === "context-menu"
  const {
    currentFolder,
    confirmDeleteFile,
    editFile,
    cropImage,
    moveFile,
    confirmDeleteSelection,
    createFolder,
    moveSelection,
    info,
  } = useMediaContext()

  return (
    <>
      {G.isNotNullable(open) && (
        <Menu.Item onClick={() => open(file.id)}>
          <Monitor aria-hidden />
          {_("display-file")}
        </Menu.Item>
      )}
      {G.isNotNullable(select) && (
        <Menu.Item onClick={() => select(file.id)}>
          <Scan aria-hidden />
          {_("select-file")}
        </Menu.Item>
      )}
      {canToggleSelect && (
        <Menu.Item onClick={() => toggleSelection()}>
          <Scan aria-hidden />
          {_(isSelected ? "unselect-file" : "select-file")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => saveAs(file.url, t(file).name)}>
        <Download aria-hidden />
        {_("download-file")}
      </Menu.Item>
      <Menu.Item onClick={() => info(file)}>
        <Info aria-hidden />
        {_("info-file")}
      </Menu.Item>
      <Menu.Item onClick={() => copyMediasFile(file.id)}>
        <CopyIcon aria-hidden />
        {_("copy-file")}
      </Menu.Item>
      <Menu.Item onClick={() => editFile(file)}>
        <PenBox aria-hidden />
        {_("edit-file")}
      </Menu.Item>
      {isImage && (
        <Menu.Item onClick={() => cropImage(file)}>
          <Scaling aria-hidden />
          {_("crop-image")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => moveFile(file.id)}>
        <FolderTree aria-hidden />
        {_("move-file")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDeleteFile(file.id)}>
        <Trash aria-hidden />
        {_("delete-file")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => createFolder(currentFolder)}>
            <FolderPlus aria-hidden />
            {_("create-folder")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={() => moveSelection()}>
                <FolderTree aria-hidden />
                {_("move-selection")}
              </Menu.Item>
              <Menu.Item onClick={() => confirmDeleteSelection()}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * FileIcon
 */
export const FileIcon: React.FC<{ file: MediasFile }> = ({ file: { extension, url } }) => {
  if (extension === "svg")
    return (
      <span className="flex justify-center items-center w-4 h-4">
        <img src={url} className="max-w-full max-h-full" />
      </span>
    )
  if (isVideo(extension)) return <Film aria-hidden size={16} className="text-muted-foreground" />
  if (isImage(extension))
    return <FileImage aria-hidden size={16} className="text-muted-foreground" />

  return (
    <span
      className={cx(
        "flex justify-center items-center text-[0.5rem] text-muted-foreground",
        extension.length <= 4 ? "text-[0.625rem]" : "text-[0.5rem]"
      )}
      aria-hidden
    >
      {truncateText(extension, 4)}
    </span>
  )
}

/**
 * DropZone
 */
type DropZoneProps = ReturnType<typeof useDropZone>
export const DropZone: React.FC<DropZoneProps> = ({ dragOver, ...props }) => {
  const { _ } = useDictionary(dictionary("drop-zone"))
  return dragOver ? (
    <div
      {...props}
      className={cx(
        "fixed inset-0 z-[100] p-2 md:p-8",
        "bg-background/80 backdrop-blur-sm animate-in fade-in-0",
        "flex justify-center items-center w-full h-full border border-dashed border-primary"
      )}
    >
      <div className="flex flex-col items-center justify-center gap-2">
        <Files aria-hidden className="text-muted-foreground my-8" size={48} strokeWidth={1} />
        <h2 className="text-2xl font-semibold">{_("title")}</h2>
        <p className="text-sm text-muted-foreground">{_("description")}</p>
      </div>
    </div>
  ) : null
}

/**
 * ImagePreview
 */
const ImagePreview: React.FC<React.PropsWithChildren<{ src: Option<string> }>> = ({
  src,
  children,
}) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Content side="right" align="center" sideOffset={4} className="px-2 py-2 ">
          <Image src={src} />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

/**
 * helpers
 */
export const imageExtensions = new Set(["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"])
export const videoExtensions = new Set(["mp4", "mov", "wmv", "flv", "avi", "mkv", "webm"])
export const isImage = (extension: string): boolean => imageExtensions.has(extension.toLowerCase())
export const isVideo = (extension: string): boolean => videoExtensions.has(extension.toLowerCase())
