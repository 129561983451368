import { z } from "zod"

/**
 * unions exports
 */
import * as headingSlider from "./headingSlider/schemas"
import * as headingVideo from "./headingVideo/schemas"
import * as articleHeading from "./articleHeading/schemas"
import * as recentsNews from "./recentsNews/schemas"
import * as actionCards from "./actionCards/schemas"
import * as factCards from "./factCards/schemas"
import * as homeCards from "./homeCards/schemas"
import * as newsletter from "./newsletter/schemas"
import * as accordionCards from "./accordionCards/schemas"
import * as heading from "./heading/schemas"
import * as accessibilityTabs from "./accessibilityTabs/schemas"
import * as richtext from "./richtext/schemas"

export const apiContentItemAny = z.union([
  headingSlider.apiContentItem,
  headingVideo.apiContentItem,
  articleHeading.apiContentItem,
  recentsNews.apiContentItem,
  actionCards.apiContentItem,
  factCards.apiContentItem,
  homeCards.apiContentItem,
  newsletter.apiContentItem,
  accordionCards.apiContentItem,
  heading.apiContentItem,
  accessibilityTabs.apiContentItem,
  richtext.apiContentItem,
])
export type ContentItemMapping = {
  headingSlider: headingSlider.ContentItem
  headingVideo: headingVideo.ContentItem
  articleHeading: articleHeading.ContentItem
  recentsNews: recentsNews.ContentItem
  actionCards: actionCards.ContentItem
  factCards: factCards.ContentItem
  homeCards: homeCards.ContentItem
  newsletter: newsletter.ContentItem
  accordionCards: accordionCards.ContentItem
  heading: heading.ContentItem
  accessibilityTabs: accessibilityTabs.ContentItem
  richtext: richtext.ContentItem
}

/**
 * default schemas and types exports
 */
export type ApiContentItemAny = z.infer<typeof apiContentItemAny>
export const apiContent = z.object({
  id: z.string().uuid(),
  items: z.array(apiContentItemAny),
})
export type ApiContent = z.infer<typeof apiContent>

import { ContentItemBase } from "@/store/contents/localizers"
import { Language } from "@/store/languages/localizers"
import { Payload } from "@/services/contents/service"
export type ItemMappingExport<T extends keyof ContentItemMapping> = {
  ItemForm: React.FC<{ item: ContentItemBase & ContentItemMapping[T]; close: () => void }>
  ItemRender: React.FC<{ item: ContentItemBase & ContentItemMapping[T] }>
  create: (order: number, languages: Language[]) => Extend<Payload["items"]["create"], FormPayload<T>>
  ItemThumbnail: React.FC
}
export type ItemMapping<T extends keyof ContentItemMapping> = {
  [K in T]: ItemMappingExport<K>
}
export type FormPayload<T extends keyof ContentItemMapping> = {
  props: ContentItemMapping[T]["props"]
  translations: {
    languageId: Language["id"]
    props: ContentItemMapping[T]["translations"][Language["id"]]["props"]
  }[]
  files: string[]
}
