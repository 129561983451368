import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Filter } from "lucide-react"

/**
 * ToolbarFilters
 */
export const ToolbarFilters: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary("components.layout.toolbar")
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant="secondary" icon>
          <Filter aria-hidden />
          <SrOnly>{_("filters-button")}</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" side="bottom" className="w-56">
        {children}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

/**
 * ToolbarFiltersToggle
 */
type ToolbarFiltersButtonProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenuPrimitive.Item
> & {
  active?: boolean
  inset?: boolean
}
export const ToolbarFiltersButton: React.FC<ToolbarFiltersButtonProps> = ({ ...props }) => {
  return <DropdownMenu.Item {...props} />
}
