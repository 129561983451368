import { useDropZone } from "@/hooks/useDropZone"
import { usePromise } from "@/hooks/usePromise"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Breadcrumb } from "@/components/layout/dashboard/breadcrumbs"
import { Error, Loader } from "@/components/collection"
import { ContextProvider, useMediaContext } from "@/components/medias/context"
import { DropZone } from "@/components/medias/components/files"
import { SelectProvider, useSelect } from "@/components/medias/useSelect"
import globalConfig, { acceptedFileExtensions } from "@/config/global"

import { createMediasFile, initMedias } from "@/store/medias/actions"
import { useFolderPath, useFreshFolder } from "@/store/medias/hooks"
import { MediasFolder } from "@/store/medias/localizers"
import { Collection } from "./Collection"

/**
 * dictionary src/dictionaries/en/pages/dashboard/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * Page: MediasIndex
 */
const MediasIndex: React.FC = () => {
  const { _ } = useDictionary(contextMapper("pages", "dashboard", "medias"))
  const { _: translate } = useDictionary(dictionary("upload-files-dialog"))

  const [initResult, initProgress] = usePromise(initMedias)
  const { currentFolder } = useMediaContext()
  const [loadingFolderResult] = useFreshFolder(currentFolder)
  const select = useSelect()
  const pathToCurrentFolder = useFolderPath(currentFolder)
  const indexBC = React.useMemo(() => [_("breadcrumbs"), "/dashboard/medias"] as Breadcrumb, [_])

  const breadcrumbs = React.useMemo(
    () => [
      indexBC,
      ...A.map<MediasFolder, Breadcrumb>(A.reverse(pathToCurrentFolder), ({ name, id }) => [
        name,
        `/dashboard/medias/${id}`,
      ]),
    ],
    [indexBC, pathToCurrentFolder]
  )

  const dropZoneProps = useDropZone({
    onDropFiles: files => {
      if (A.isEmpty(files)) return
      let counter = 1
      const total = files.length
      const toastId = toast.promise(
        Promise.all(
          A.map(files, async file => {
            const { error } = await createMediasFile({ folderId: currentFolder, file })
            if (error) toast.error(translate("error"), { description: file.name })
            toast.loading(translate("progress", { counter: counter++, total }), {
              id: toastId,
            })
          })
        ),
        {
          loading: translate("progress", { counter, total }),
          success: translate("success"),
        }
      )
    },
    accept: acceptedFileExtensions,
    max: globalConfig.maxUploadFile,
  })

  if (initProgress) return <Loader breadcrumbs={[indexBC]} />

  if (initResult.error || loadingFolderResult?.error)
    return (
      <Error
        breadcrumbs={[indexBC]}
        title={_("loading-error.title")}
        description={_("loading-error.description")}
      />
    )
  return (
    <PageWrapper ref={select.ref} {...dropZoneProps.bindDropZone}>
      <DropZone {...dropZoneProps} />
      <PageHeader breadcrumbs={breadcrumbs} />
      <SelectProvider {...select.props} />
      <Collection />
    </PageWrapper>
  )
}
type PageProps = {
  id: string | null | undefined
}
export default ({ id }: PageProps) => {
  return (
    <ContextProvider
      current={id || null}
      contextKey="medias-index"
      selectFolder="multiple"
      selectFile="multiple"
    >
      <MediasIndex />
    </ContextProvider>
  )
}
